#App {
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0%;
  overflow: hidden;
}

.sideBar {
  height: 6.9%;
  width: 100%;
  position: fixed;
  /* z-index: 1; */
  z-index: 43;
  top: 0;
  left: 0px;
  display: flex;
  padding-top: 20px;
  margin-left: 20px;
}


#birdIcon {
  height: 10.7vh;
  margin-top: 1vh;
  margin-left: .1vw;
}

#menuIcon {

  margin-left: 87.4vw;
  margin-top: 4vh;
  width: 2.18vw;
  height: 2.59vh;
}

.nature-symphony-button {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

@media(min-width:280px) and (max-width:767px) {
  #birdIcon {
    height: 50px;
    margin-top: -1vh;
    margin-left: 13.1vw;
  }

  .sideBar {
    /* display: flex;
    justify-content: center;
    align-items: center;
    top: 1%; */

    display: flex;
    align-items: center;
    top: 2%;
    left:-7vh;
  }

}

@media(min-width:768px) and (max-width:1023px) {
  #birdIcon {
    height: 10.7vh;
    margin-top: -1vh;
    margin-left: 5.1vw;
  }

  .sideBar {
    display: flex;
    left: -4%;
    /* justify-content: center; */
  }
}
