@import url("https://fonts.googleapis.com/css2?family=Averia+Libre:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

#about-us {
  text-align: center;
}

#ab-sec-one {
  background-image: url("../../assets/images/landing-page/about-us-bg-one.jpg");
  height: 80vh;
  width: 100vw;
  font-family: "Averia Libre", cursive;
  font-size: 130px;
  font-weight: bold;
  line-height: 179px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
#ab-sec-two {
  background-image: url("../../assets/images/landing-page/about-us-bg-two.jpg");
  height: 90vh;
  width: 100vw;
  background-size: cover;
  color: white;
}

.about-us-heading {
  font-family: "Averia Libre", cursive;
  font-size: 4.7vh;
}

.about-us-content {
  font-family: "Poppins", sans-serif;
  font-size: 2vh;
  padding: 1vh 23vw;
  line-height: 24px;
  letter-spacing: 0em;
}

#ab-sec-three {
  background-image: none;
  background-size: auto;
  background-repeat: no-repeat;
  /* height: 90vh; */
  height: auto !important;
  
  width: 100vw;
  color: black;
}

#ab-sec-four,
#ab-sec-five {
  color: black;
  /* height: 100vh; */
  height: auto;
  width: 100vw;
}

#ab-sec-five {
  background-image: url("../../assets/images/landing-page/about-us-bg-four.jpg");
  background-size: cover;
}

#ab-sec-six {
  /* background-image: url("../../assets/images/landing-page/about-us-bg-five.jpg"); */
  background-color: #db8f44;
  height: auto !important;
  background-repeat: no-repeat;
  width: 100vw;
  background-size: auto;
  padding: 10vh 0vw;
}

.mentor-image,
.intern-image {
  margin: 0vh 2vw;
}

.about-us-subheading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3.2vh;
}

#ab-sec-seven {
  color: black;
  padding: 2% 0% 0% 0%;
}

.intern-section {
  display: flex;
  justify-content: center;
}

.intern-profile {
  width: 19vw;
}

.intern-name,
.intern-dept {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.intern-name {
  font-weight: bold;
  margin-bottom: 0;
}

.intern-dept {
  margin-top: 0;
}

#ab-sec-eight {
  background-image: url("../../assets/images/landing-page/about-us-bg-six.jpg");
  padding: 20vh 0vw 10vh 0vw;
  background-size: cover;
}


/* ============New Code Css ============== */
.ab-secmain-seven {
  height: auto !important;
  border: 0px solid yellow;
  width: 100vw !important;
}

.seven-main {
  width: 90%;
  border: 0px solid red;
  margin: 0% auto !important;
  display: flex !important;
  justify-content: center;
  padding: 3% 0% 0% 0%;
}

.seven-main-first {
  width: 30%;
  border: 0px solid yellow !important;
}


@media screen and (min-width: 280px) and (max-width: 767px) {
  #ab-heading {
    font-size: 60px;
  }



  .about-us-content {
    font-family: "Poppins", sans-serif;
    font-size: 1.97vh;
    padding: 0vh 4vw;
    line-height: 1.2;
    letter-spacing: 0em;
  }

  #ab-sec-five {
    border: 0px solid red !important;
    /* position: relative !important; */
    /* top: 10%; */
  }

  #ab-sec-six {
    /* background-image: url("../../assets/images/landing-page/about-us-bg-five.jpg"); */
    background-color: #db8f44;
    height: auto !important;
    width: 100vw;
    background-repeat: no-repeat !important;
    background-size: auto;
    padding: 10vh 0vw;
    border: 0px solid red;
  }

  .seven-main {
    width: 90%;
    border: 0px solid red;
    margin: 0% auto !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    padding: 5% 0% 0% 0%;
  }

  .seven-main-first {
    width: 90%;
    border: 0px solid yellow !important;
    margin: auto;
  }

  .intern-image {
    width: 90%;
    height: 90%;
    padding: 10px 0px;
  }

  .intern-dept {
    font-size: 16px !important;
  }

  .intern-name {
    font-size: 16px !important;
  }

  #Interns {
    font-size: 30px !important;
    font-family: "Poppins", sans-serif;

  }

  #ab-sec-seven {
    color: black;
    padding: 10% 0% 0% 0%;
  }

  .osch-logo {
    width: 90% !important;
  }

  .yuva-in-culture-logo {
    width: 90% !important;
  }

  .osch-logo {
    width: 90% !important;
  }
  #ab-sec-three {
    background-image: none;
    background-size: auto;
    background-repeat: no-repeat;
    /* height: 90vh; */
    height: auto !important;
    
    width: 100vw;
    color: black;
  }
  #ab-sec-two {
    background-image: url("../../assets/images/landing-page/about-us-bg-two.jpg");
    height: 100vh !important;
    width: 100vw;
    background-size: cover;
    color: white;
    border: 0px solid red;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

  #ab-heading {
    font-size: 60px;
  }

  .about-us-content {
    font-family: "Poppins", sans-serif;
    font-size: 1.97vh;
    padding: 1vh 2vw;
    line-height: 1.2;
    letter-spacing: 0em;
  }

  .seven-main {
    width: 90%;
    border: 0px solid red;
    margin: 0% auto !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    padding: 5% 0% 0% 0%;
  }

  .seven-main-first {
    width: 60%;
    border: 0px solid yellow !important;
    margin: auto;
  }

  .intern-image {
    width: 100%;
    height: 100%;
  }

  .intern-dept {
    font-size: 25px !important;
  }

  .intern-name {
    font-size: 25px !important;
  }

  #ab-sec-seven {
    color: black;
    padding: 10% 0% 0% 0%;
  }

  #Interns {
    font-size: 50px !important;
    font-family: "Poppins", sans-serif;

  }
}