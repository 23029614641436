@media only screen and (min-width: 500px){

  .AppContainer {
    position: absolute;
    width:40.01%;
    height: 60.2%;
    top: 21.85%;
    left: 25.15%;
  
    .container {
      padding: 11px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);
      justify-items: center;
      align-items: stretch;
      gap: 1rem;
      margin: 0 0;
      width: 100%;
      height: 100%;
      perspective: 100%;
      max-width: 720px;
      &.is-open {
        opacity: 0.6;
        filter: blur(11px);
      }
      &.show-fact {
        opacity: 0.6;
        filter: blur(11px);
      }
    }
    
  }
 
  .match-pop{
    width: 100%;
    height: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 15px;
    text-transform: capitalize;
    text-align: center;
  }
  .funFact{
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-transform: capitalize;
    color: #000000;
  }
}
@media only screen and (max-width: 700px){
.bold {
    font-weight: 600;
    text-transform: uppercase;
  }
  .AppContainer {
    position: absolute;
    width:70%;
    height: 40%;
    margin-top: 50%;
    margin-bottom: 0%;
    margin-left: 3%;
  
 
  
    .container {
      padding: 11px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);
      justify-items: center;
      align-items: stretch;
      gap: 1rem;
      margin: 0 0;
      width: 338px;
      height: 285px;
      perspective: 100%;
      max-width: 720px;
      &.is-open {
        opacity: 0.6;
        filter: blur(11px);
      }
      
    }

    
  }
  .match-pop{
    width: 100%;
    height: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 15px;
    text-transform: capitalize;
    text-align: center;
  }

}