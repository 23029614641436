@import url("https://fonts.googleapis.com/css2?family=Averia+Libre&display=swap");

body {
  font-family: "Averia Libre", cursive;
  color: white;
}

#pg-four {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-size: cover;
  background-image: url("../../assets/images/landing-page/screen-four44.jpg");
}

#content-four-1 {
  font-size: 1.5vw;
  width: 18.7vw;
  height: 20vh;
  position: fixed;
  top: 33vh;
  right: 19vw;
  text-align: left;
}

#content-four-2 {
  font-size: 1.5vw;
  width: 18.7vw;
  height: 20vh;
  position: fixed;
  top: 31vh;
  right: 19vw;
  text-align: left;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  #content-four-1 {
    font-size: 8vw;
    width: 90% !important;
    top: 5vh;
    right: 0vw;
  }

  #pg-four {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-size: cover;
    background-image: url("../../assets/images/landing-page/screen-four.jpg");
  }

  #content-four-2 {
    font-size: 8vw;
    width: 90% !important;
    top: 5vh;
    right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

  #content-four-1 {
    font-size: 7vw;
    width: 90% !important;
    top: 5vh;
    text-align: right !important;
    right: 3.3vw ! important;

  }
  #pg-four {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-size: cover;
    background-image: url("../../assets/images/landing-page/screen-four.jpg");
  }

  #content-four-2 {
    font-size: 7vw;
    width: 90% !important;
    top: 5vh;
    text-align: right !important;
    right: 3.3vw ! important;

  }
}