.custommaindiv{
    padding: 23px;
    display: flex;
    position: fixed;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    width: 100%;
    z-index: 1;
}
.logonavclass{
    width: 14%;
}
@media screen and (min-width: 268px) and (max-width: 768px) {
    .logonavclass{
        width: 20%;
    }
}