@import url("https://fonts.googleapis.com/css2?family=Averia+Libre&display=swap");

body {
  font-family: "Averia Libre", cursive;
  color: white;
}

#pg-one {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url("../../assets/images/landing-page/screen-one11.jpg");
  background-size: cover;
}

.skip {
  color: white;
  background-color: transparent;
  border-color: transparent;
  font-size: 1.1vw;
  position: fixed;
  bottom: 35px;
  right: 50px;
  text-decoration: underline;
  cursor: pointer;
}

#content-one {
  font-size: 5.5vw;
  width: 57vw;
  height: 30vh;
  position: fixed;
  top: 28vh;
  right: 3.3vw;
  text-align: right;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  #content-one {
    font-size: 7.5vw;
  }
  #pg-one {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: url("../../assets/images/landing-page/screen-one.jpg");
    background-size: cover;
  }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #content-one {
    font-size: 7vw;
  }
  #pg-one {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: url("../../assets/images/landing-page/screen-one.jpg");
    background-size: cover;
  }
}