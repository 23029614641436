.mainGreyStone {
    display: flex;
    width: 149.47vw;
    position: relative;
    overflow: hidden;
    z-index: -1;
    height: 100vh;
    background-color: #333333;
}

#strokeBlueStone {
    margin-left: -34vw;

}

.GreyStoneHeading {
    position: absolute;
    font-family: 'Averia Libre';
    font-style: normal;
    font-weight: 700;
    line-height: 103%;
    color: #FFFFFF;
    width: 40.80vw;
    height: 49.62vh;
    left: 10.99vw;
    top: 24.49vh;
    font-size: 12.4vh;

}

.GreyStonePara {
    position: absolute;
    width: 27.98vw;
    height: 13.38vh;
    left: 57vw;
    top: 24.49vh;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2.3vh;
    line-height: 3.4vh;
    color: #FFFFFF;
}

.recordimg {
    position: absolute;
    width: 31.37vw;
    height: 40.22vh;
    left: 67vw;
    top: 56vh;
    z-index: 10;

}

.stroke2 {
    position: absolute;
    width: 42.45vw;
    height: 50.88vh;
    left: 68vw;
    top: 45vh;
}

#gsp2 {
    left: 110vw;
    top: 48.49vh;
    width: 22.98vw;
    height: 13.38vh;
}

#greyStoneVideoButton {
    margin-left: 110vw;
    margin-top: 82vh;
}

.stone {
    position: absolute;
    left: 89.14%;
    right: 38.75%;
    top: 9.02%;
    bottom: 4.91%;
    width: 22.62vw;
    height: 68.75vh;


}



.wlayer {
    position: absolute;
    left: 89.14%;
    right: 36.01%;
    top: 3.43%;
    bottom: 16.64%;
    width: 24.45vw;
    height: 63.33vh;



}

.blayer {
    position: absolute;
    left: 90.14%;
    right: 41.35%;
    top: 14.02%;
    bottom: 24.63%;
    width: 18.46vw;
    height: 45.9vh;

}

.shadow {
    position: absolute;
    left: 82.6%;
    right: 38.9%;
    top: 59.55%;
    bottom: 11.79%;

    background: #3E3C38;
    opacity: 0.5;
    border: 1px solid #000000;
    filter: blur(78px);
}

#skinstone {
    left: -17.14%;
}

#skinbl {
    left: -15.8%;
}

#skinwl {
    left: -17.14%;
}

@media(min-width:280px) and (max-width:767px) {
    .mainGreyStone {
        display: flex;
        width: 100vw;
        position: relative;
        top: -15px;
        /* overflow: hidden; */
        /* z-index: -1; */
        height: 170vh;
        background-color: #333333;
    }

    .GreyStoneHeading {
        position: absolute;
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        line-height: 103%;
        color: #FFFFFF;
        width: 76.8vw;
        height: 49.62vh;
        left: 6.99vw;
        top: 11.49vh;
        font-size: 34px;
    }

    .GreyStonePara {
        position: absolute;
        width: 88vw;
        height: 13.38vh;
        left: 7vw;
        top: 25.49vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 2vh;
        line-height: 3vh;
        color: #FFFFFF;
        text-align: left;
    }

    #strokeBlueStone {
        display: none !important;
    }

    .recordimg {
        position: absolute;
        width: 62.37vw;
        /* height: 26.22vh; */
        height: auto;
        left: 12vw;
        top: 60vh;
        z-index: 10;
    }

    .stroke2 {
        position: absolute;
        width: 59.45vw;
        height: 30vh;
        left: 40vw;
        top: 56vh;
    }

    #gsp2 {
        left: 8%;
        top: 91.49vh;
        width: 88vw;
        height: 13.38vh;
    }

    #greyStoneVideoButton {
        margin-left: 8%;
        margin-top: 127vh;
    }
    .stone {
        position: absolute;
        left: 36%;
        /* right: 38.75%; */
        top: 84%;
        bottom: 4.91%;
        width: 58vw;
        /* height: 61.75vh; */
        /* height: fit-content; */
        height: auto;
    }

        .wlayer {
        position: absolute;
        left: 43.14%;
        right: 36.01%;
        top: 83%;
        bottom: 16.64%;
        width: 53.45vw;
        /* height: 61.33vh; */
        /* height: fit-content; */
        height: auto;
    }

    .blayer {
        position: absolute;
        left: 43.14%;
        /* right: 41.35%; */
        top: 86.02%;
        bottom: 24.63%;
        width: 46.46vw;
        /* height: 45.9vh; */
        /* height: fit-content; */
        height: auto;
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .mainGreyStone {
        display: flex;
        width: 100vw;
        position: relative;
        top: -26px;
        /* overflow: hidden; */
        z-index: 0;
        height: 170vh;
        background-color: #333333;
    }

    .GreyStoneHeading {
        position: absolute;
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        line-height: 103%;
        color: #FFFFFF;
        width: 76.8vw;
        height: 49.62vh;
        left: 6.99vw;
        top: 9.49vh;
        font-size: 64px;
    }

    .GreyStonePara {
        position: absolute;
        width: 80vw;
        height: 13.38vh;
        left: 7vw;
        top: 23.49vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 2.3vh;
        line-height: 3.1vh;
        color: #FFFFFF;
        text-align: left;
    }

    #strokeBlueStone {
        display: none !important;
    }

    .recordimg {
        position: absolute;
        width: 51.37vw;
        height: 34.22vh;
        left: 16vw;
        top: 56vh;
        z-index: 10;
    }

    .stroke2 {
        position: absolute;
        width: 42.45vw;
        height: 42.88vh;
        left: 47vw;
        top: 50vh;
    }

    #gsp2 {
        left: 7vw;
        top: 93.49vh;
        width: 80vw;
        height: 13.38vh;
    }

    #greyStoneVideoButton {
        margin-left: 8vw;
        margin-top: 120vh;
    }

    /* .stone {
        position: absolute;
        left: 41.14%;
        right: 38.75%;
        top: 60.02%;
        bottom: 4.91%;
        width: 53.62vw;
        height: 61.75vh;
    }

    .wlayer {
        position: absolute;
        left: 43.14%;
        right: 36.01%;
        top: 55.43%;
        bottom: 16.64%;
        width: 53.45vw;
        height: 61.33vh;
    }

    .blayer {
        position: absolute;
        left: 45.14%;
        right: 41.35%;
        top: 61.02%;
        bottom: 24.63%;
        width: 46.46vw;
        height: 45.9vh;
    } */

    .stone {
        position: absolute;
        left: 36%;
        /* right: 38.75%; */
        top: 79%;
        bottom: 4.91%;
        width: 58vw;
        height: 61.75vh;
    }

    .wlayer {
        position: absolute;
        left: 43.14%;
        right: 36.01%;
        top: 75%;
        bottom: 16.64%;
        width: 53.45vw;
        height: 61.33vh;
    }

    .blayer {
        position: absolute;
        left: 43.14%;
        /* right: 41.35%; */
        top: 81.02%;
        bottom: 24.63%;
        width: 46.46vw;
        height: 45.9vh;
    }

}