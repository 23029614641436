@media only screen and (min-width: 500px){
    .exitScreenPara{
        position: absolute;
        width: 400px;
        height: 60px;
        left: 39.63%;
        top: 41.57%;
        
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;
        color: #000000;
    }
    .replay{
        position: absolute;
width: 14.38%;
height: 6.64%;
left: 36.27%;
top: 57.55%;
cursor: pointer;
background: #DC8138;
box-shadow: 1px 4px 10px 4px rgba(255, 255, 255, 0.25);
border-radius: 10px;


.replayButton{
    background: none;
    color: inherit;
    border: none;
    color: white ;
    margin-top: px;
    margin-left: 27%;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 27px;
cursor: pointer;
line-height: 48px;
position: absolute;
    
}
}
.quit{
    position: absolute;
width: 14.38%;
height: 6.64%;
left: 54.27%;
cursor: pointer;
top: 57.55%;
border: 3px solid #DC8138;
box-shadow: 1px 4px 10px 4px rgba(255, 255, 255, 0.25);
border-radius: 10px;


.quitButton{
background: none;
cursor: pointer;
color: inherit;
border: none;
color: #DC8138 ;
margin-top: px;
margin-left: 32%;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 27px;
line-height: 48px;
position: absolute;

}
}
}