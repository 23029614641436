.hideshowhorizontal{
    display: block !important;
}

.hideshowhorizontalmoble {
    display: none;
}


@media(min-width:280px) and (max-width:767px) {
.hideshowhorizontal {
        display: none !important;
    }

    .hideshowhorizontalmoble{
        display: block;
    }

}
 
@media(min-width:768px) and (max-width:1023px) {
    .hideshowhorizontal {
        display: none !important;
    }
        .hideshowhorizontalmoble {
            display: block;
        }

}