
   @media only screen and (min-width: 500px){
    .containerOuter {
      margin-top: 0;
      justify-self: center;
      width: 100vw;
      height: 100vh;

      .containerInner {
      background-image: url("../../../assets/images/natures-symphony/game/desktop/game-bg.jpg");
      height: 100vh;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
        .header1{
          @import url('https://fonts.googleapis.com/css2?family=Averia+Libre:wght@300&display=swap');
          position: absolute;
          margin-left: 78.33333333%;
       
          margin-top: 0.6%;
          
          font-family: Averia Libre;
          font-size: 11vh !important;
          line-height: 7vh !important;
          padding: 7vh 0vh 0vh 0vh !important;
          font-weight: 400;
          letter-spacing: 0em;
          width: 8.33333333%;
          color: rgba(220, 129, 56, 1);
        }

        .header2{
          position: absolute;
          margin-left: 78.33333333%;
          top: 1.3%;
      
          font-family: Averia Libre;
          font-size: 11vh !important;
          line-height: 7vh !important;
          padding: 7vh 0vh 0vh 0vh !important;
          font-weight: 400;
          letter-spacing: 0em;
          width: 8.33333333%;
          color: rgba(220, 129, 56, 1);
        }

        .pause{
          position: absolute;
          top: 59.83%;
          margin-left: 69.21%;
          width: 22.5px;
          height: 26.25px;

        }
        .homeIconButton{
          background: none;
          color: inherit;
          border: none;
          cursor: pointer;
        }
        .homeIcon{
          position: absolute;
          top: 7.6%;
          left: 2.5%;
          cursor: pointer;
        }
        .soundButton{
          background: none;
          color: inherit;
          border: none;
          width: 40px;
          cursor: pointer;
        }
        .sound{
          position: absolute;
          top: 67.64%;
          margin-left: 69.21%;
        }
        
        .howtoplay{
          position: absolute;
          width: 168px;
          height: 34px;
          cursor: pointer;
          top: 75.84%;
          margin-left: 72.513%;
          

          .htpbutton{
            background: none;
	          color: inherit;
	          border: none;
            cursor: pointer;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 22px !important; 
            line-height: 36px;
            color: #DC8138;
          }
          
        }

        .info{
          position: absolute;
          top: 75.64%;
          margin-left: 69.21%;
          color: #DC8138;
        }
      }
      
        
  }

  }
