html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  font-family: system-ui, sans-serif;
  min-height: 100vh;
  margin: 0;
}
#menu-togglerabout{

  position: relative;
  top: -30%;
  /* right: 2.5vw; */
  cursor: pointer;
  left: 67%;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}

.nav-item {
  list-style-type: none;
}

/* global styles for lore and legends */

#legend-lore {
  background-image: url("../src/assets/images/lore-and-legend/background.png");
}
.Peacockclass{
  width: 140% !important;
}
.redclasswidth{
  width: 150% !important;
}

.Peacockclassimg{
  margin-left: 66% !important;
  width: 78% !important;
}
#legend-lore-menu {
  position: absolute;
  height: 10% !important;
  width: 100%;
  background-color: transparent;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
}

#menu-toggler {
  position: fixed;
  top: 6%;
  right: 2.5vw;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
#menu-toggler-about {
  position: fixed;
  top: 4.5vh;
  right: 2.5vw;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.songsofnaturelogobtn {
  position: fixed;
  top: 6%;
  right: 2.5vw;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;

      position: fixed;
    top: 2.5vh !important;
    right: 2.5vw;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
}

/* global styles for birds in music */

#birds-in-music {
  background-image: url("../src/assets/images/birds-in-music/background.png");
  height: 100vh;
}

#birds-in-music-menu {
  position: absolute;
  height: 10% !important;
  width: 100%;
  background-color: transparent;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
}

@media(min-width:280px) and (max-width:767px) {
  #menu-toggler {
    /* position: fixed;
    top: 4%;
    right: 2.5vw;
    cursor: pointer; */
    /* position: relative;
    top: -1%;
    right: 2.5vw;
    cursor: pointer;
    left: -60%; */
    /* border: 1px solid red; */

        position: relative;
          top: -30%;
          /* right: 2.5vw; */
          cursor: pointer;
          left: 63%;          
  }
  #menu-togglerabout{
    position: absolute;
    top: 3vh;
    right: 2.5vw;
    cursor: pointer;
  }
  #menu-toggler-about{
    position: absolute;
    /* top: 1.5vh;
    right: -0.5vw;
    right: 1.5vw;
    top: 2.5vh; */
    right: 4.5vw;
    top: 2vh;
    cursor: pointer;
  }
   #menuIconfirst{
    width: 85% !important;
   }
   .custombutton{
    background-color: aqua;
   }
  .songsofnaturelogobtn {
    /* position: relative;
    top: 4%;
    right: 2.5vw;
    cursor: pointer;
    left: 20%; */

    /* position: relative; */
      /* top: 5%; */
      /* right: -84vw;
      cursor: pointer; */
      /* left: 38vh; */

      position: fixed;
      top: 4.5vh;
      right: 2.5vw;
      background-color: transparent;
      border-color: transparent;
      cursor: pointer;

  }

  #birds-in-music {
    background-image: url("../src/assets/images/birds-in-music/background.png");
    height: 100vh;
    position: fixed;
    overflow-y: auto;
  }
}


.hidemobile {
  display: block;
}

.showMobile {
  display: none;
}


@media(min-width:280px) and (max-width:767px) {
  .hidemobile {
    display: none;
  }

  .showMobile {
    display: block;
  }

  #birds-in-music-menu {
    position: fixed;
  }
  #songsofnaturetogel{
    width: 90% !important;    
  }
  
}

@media(min-width:768px) and (max-width:1023px) {

  .hidemobile {
    display: none;
  }

  .showMobile {
    display: block;
  }

  #birds-in-music {
    background-image: url("../src/assets/images/birds-in-music/background.png");
    height: 100vh;
    position: fixed;
    overflow-y: auto;
  }

  #birds-in-music-menu {
    position: fixed;
    margin: 4% 10px;
  }

  #menu-toggler {
    top: 4%;
  }

  #menu-toggler {
    /* position: fixed;
      top: 4%;
      right: 2.5vw;
      cursor: pointer; */
    /* position: relative;
    top: 4%;
    right: 2.5vw;
    cursor: pointer;
    left: -57%; */
    /* border: 1px solid red; */


        position: relative;
          top: 4%;
          right: -68.5vw;
          cursor: pointer;
          /* left: -57%; */

    
  }

  .songsofnaturelogobtn {
    /* position: relative;
    top: 4%;
    right: 2.5vw;
    cursor: pointer;
    left: 20%; */

    /* position: relative;
      top: 4%;
      right: -87.5vw;
      cursor: pointer; */
      position: fixed;
      top: 4.5vh;
      right: 2.5vw;
      background-color: transparent;
      border-color: transparent;
      cursor: pointer;
  }

  #songsofnaturetogel {
    width: 140% ! important;
    height: 100% !important;
  }

  #songnamedlogo {
    width: 18% ! important;
    height: 12% !important;

  }
}

@media(min-width:1279px) and (max-width:1281px){
  
  .redclasswidth{
    width: 156% !important;
  }
  
}
@media(min-width:1535px) and (max-width:1538px){
  .redclasswidth{
    width: 158% !important;
  }
}

@media(min-width:1365px) and (max-width:1368px){
  
  .redclasswidth{
    width: 170% !important;
  }
}

@media screen and (max-width: 1440px) {
  .Bulbulclassimg {
      margin-bottom: -7.5% !important;
    }
}