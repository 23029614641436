.mainOrangeNest {
    display: flex;
    width: 82.91vw;
    position: relative;
    overflow: hidden;
    z-index: -1;
    height: 100vh;
    background-color: #D56D54;
}

.orangenestpara {
    position: absolute;
    width: 49.4vw;
    height: 4.16vh;
    left: 17vw;
    top: 44vh;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 2.7vh;
    line-height: 3.6vh;
    text-align: center;
    color: #FFFFFF;
}

.nesteggs {
    position: absolute;
    width: 39.17vw;
    height: 41.48vh;
    left: 41vw;
    top: 57vh;
}

@media(min-width:768px) and (max-width:1023px) {
    .mainOrangeNest {
        display: flex;
        width: 100vw;
        position: relative;
        overflow: hidden;
        z-index: -1;
        height: 100vh;
        background-color: #D56D54;
    }

    .orangenestpara {
        position: absolute;
        width: 86.4vw;
        height: 4.16vh;
        left: 8vw;
        top: 12vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 2.7vh;
        line-height: 3.6vh;
        text-align: center;
        color: #FFFFFF;
    }

    .nesteggs {
        position: absolute;
        width: 79.17vw;
        height: 49.48vh vh;
        left: 11vw;
        top: 42vh;
    }


}


@media(min-width:280px) and (max-width:767px) {

    .mainOrangeNest {
        display: flex;
        width: 100vw;
        position: relative;
        overflow: hidden;
        z-index: -1;
        height: 80vh;
        background-color: #D56D54;
    }

    .orangenestpara {
        position: absolute;
        width: 86.4vw;
        height: 4.16vh;
        left: 8vw;
        top: 12vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 2.7vh;
        line-height: 3.6vh;
        text-align: center;
        color: #FFFFFF;
    }

    .nesteggs {
        position: absolute;
        width: 79.17vw;
        /* height:fit-content; */
        height: auto;
        left: 11vw;
        top: 44vh;
    }
}