@media only screen and (min-width: 500px){
.card {
    width: 105%;
    height: 110.3%;
    border-radius: 10px;
    transition: 0.3s;
    transform-style: preserve-3d;
    position: relative;
    cursor: pointer;
    
    .card-img {
      width: 100%;
      height: 100%;
    }
  
    
    .card-face {
      backface-visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      &.card-back-face {
        transform: rotateY(180deg);
      }
    }
  
    &.is-flipped {
      box-shadow: 10px 4px 4px 2px rgba(1, 1, 0, 0.5);
    }
  
    &.is-inactive {
      opacity: 0;
      pointer-events: none;
    }
  }
}
@media only screen and (max-width: 700px){
.card {
    width: 100%;
    height: 80%;
    border-radius: 10px;
    transition: 0.3s;
    transform-style: preserve-3d;
    position: relative;
    cursor: pointer;
    
    .card-img {
      width: 77px;
      height: 60px;
    }
    
    .card-face {
      backface-visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      &.card-back-face {
        transform: rotateY(180deg);
      }
    }
  
    &.is-flipped {
      box-shadow: 10px 4px 4px 2px rgba(1, 1, 0, 0.5);
    }
  
    &.is-inactive {
      opacity: 0;
      
    }
  }
}