.mainFinalGrey {
    display: flex;
    width: 142vw;
    position: relative;
    overflow: hidden;
    /* z-index: -1; */
    height: 100vh;
    background-color: #333333;
    /* background-color:#F4EACE; */
}

.finalGreyPara {
    position: absolute;
    width: 30.98vw;
    height: 13.38vh;
    left: 14vw;
    top: 26.49vh;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2.5vh;
    line-height: 3.6vh;
    color: #FFFFFF;
}

#finalGreyVideoButton {
    margin-left: 14vw;
    margin-top: 60vh;
}

#orange {
    margin-left: 70vw;
    width: 42vw;
}

.controller {
    position: absolute;
    width: 3.94vw;
    height: 6.79vh;
    left: 91.4vw;
    top: 10vh;
    align-items: center;
}

.artboard {
    position: absolute;
    width: 24.02vw;
    height: 23.96vh;
    left: 81.5vw;
    top: 20vh;
    border-radius: 20px;
}

.gameHeading {
    /* position: absolute;
    width: 13.66vw;
    height: 11vh;
    left: 87vw;
    top: 48vh; */
    position: absolute;
    width: 13.66vw;
    height: 11vh;
    left: 86.7vw;
    top: 48vh;
}

#GamePara {
    margin-left: 82vw;
    margin-top: 63vh;
    width: 23vw;
    text-align: center;
    font-size: 2.1vh;
}

#gameButton {

    color: #000000;
    background: #F4EACE;
    width: 11.11vw;
    height: 5.44vh;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    border: none;
    font-size: 2.2vh;
    margin-top: 87vh;
    /* margin-left: 61.5vw; */
    margin-left: 60.5vw;
    z-index: 20;
    cursor: pointer;
}

#gameButton:hover {
    background-color: #E3927E;
}

@media(min-width:280px) and (max-width:767px) {
    .hidemobile {
        display: none;
    }

    .mainFinalGrey {
        height: 150vh;
        position: relative;
        top: -15px;

    }

    .finalGreyPara {
        position: absolute;
        width: 78vw;
        height: 13.38vh;
        /* left: 8.5vw; */
        left: 10.99vw;
        top: 8.49vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 2vh;
        line-height: 3vh;
        color: #FFFFFF;
        text-align: left;
    }

    #finalGreyVideoButton {
        margin-left: 25.5vw;
        margin-top: 45vh;
    }

    #orange {
        margin-left: -39vw;
        width: 164vw;
        height: 112vh;
        margin-top: -21%;
    }

    .controller {
        position: absolute;
        width: 18.94vw;
        height: 6.79vh;
        left: 41.4vw;
        top: 64vh;
        align-items: center;
    }

    .artboard {
        position: absolute;
        width: 82.02vw;
        height: 24.96vh;
        left: 9.5vw;
        top: 74vh;
        border-radius: 20px;
    }

    .gameHeading {
        position: absolute;
        width: 49.66vw;
        height: 11vh;
        left: 25vw;
        top: 103vh;
    }

    #GamePara {
        margin-left: 4vw;
        margin-top: 118vh;
        width: 92vw;
        text-align: center;
        line-height: 3.1vh;
        font-size: 2vh;
    }

    #gameButton {
        width: 34vw;
        height: 5.44vh;
        font-weight: 700;
        font-size: 2.2vh;
        margin-top: 137vh;
        margin-left: -40vw;
        z-index: 20;
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .hidemobile {
        display: none;
    }

    .mainFinalGrey {
        height: 150vh;
        top: -26px;

    }

    .finalGreyPara {
        position: absolute;
        width: 85vw;
        height: 13.38vh;
        left: 9vw;
        top: 4.49vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 2.5vh;
        line-height: 3.6vh;
        color: #FFFFFF;
    }

    #finalGreyVideoButton {
        margin-left: 36vw;
        margin-top: 39vh;
    }

    #orange {
        margin-left: -39vw;
        width: 164vw;
        height: 112vh;
        margin-top: -31%;
    }

    .controller {
        position: absolute;
        width: 16.94vw;
        height: 8.79vh;
        left: 42.4vw;
        top: 54vh;
        align-items: center;
    }

    .artboard {
        position: absolute;
        width: 69.02vw;
        height: 26.96vh;
        left: 17.5vw;
        top: 66vh;
        border-radius: 20px;
    }

    .gameHeading {
        position: absolute;
        width: 43.66vw;
        height: 11vh;
        left: 31vw;
        top: 96vh;
    }

    #GamePara {
        margin-left: 16vw;
        margin-top: 115vh;
        width: 75vw;
        text-align: center;
        line-height: 3.1vh;
        font-size: 2vh;
    }

    #gameButton {
        width: 33.11vw;
        height: 5.44vh;
        font-weight: 700;
        font-size: 2.2vh;
        margin-top: 136vh;
        margin-left: -28.5vw;
        z-index: 20;
    }

}