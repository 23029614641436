.emilymainsection {
    display: flex;
    position: relative;
    overflow: hidden;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    background-color: #F4EACE;
}

.BirdLotus {
    width: 53.1vw;
    height: 63.13vh;
    margin-left: 2vw;
}

.NSheading {
    font-family: 'Averia Libre';
    font-style: normal;
    font-weight: 700;
    font-size: 12.9vh;
    line-height: 26.84%;
    letter-spacing: 0.0em;
    text-transform: capitalize;
    color: #D56D54;
    z-index: 6;
    position: absolute;

}

#Nhead {
    margin-left: 9.57vw;
    margin-top: 57.3vh;

}

#Shead {
    margin-left: 15.99vw;

}

.emilyDickonson {
    width: 42vw;
    height: 88.75vh;
    margin-left: 69vw;
    margin-top: 11.55vh;
    position: absolute;


}

.emilyDickonson1 {
    width: 42vw;
    height: 88.75vh;
    margin-top: 11.4vh;
    position: absolute;
    margin-left: -31.34vw;


}

.orangeEmilyPara {
    width: 29.7vw;
    height: 14.72vh;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.9vh;
    line-height: 30px;
    color: white;
    margin-left: 7.81vw;
    margin-top: 36.40vh;
}

.mainorangeEmily {
    display: flex;
    width: 60.14vw;
    position: relative;
    overflow: hidden;
    z-index: -1;
    height: 100vh;
    background-color: #D56D54;
}


@media(min-width:280px) and (max-width:767px) {
    .emilymainsection {
        display: flex;
        position: relative;
        overflow: hidden;
        z-index: -1;
        height: 85vh;
        width: 100vw;
        background-color: #F4EACE;
    }

    .BirdLotus {
        width: 100%;
        height: 34.13vh;
        /* height: fit-content; */
        margin-left: 39vw;
        margin-top: 17vw;
    }

    .NSheading {
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        /* font-size: 2.9vh; */
        font-size: 32px;
        line-height: 26.84%;
        /* letter-spacing: 0.0em; */
        text-transform: capitalize;
        color: #D56D54;
        /* z-index: 6; */
        /* margin-top: -10%; */
        position: absolute;
        left: 5%;
        top: -23%;
        width: 90%;
        /* border: 1px solid red; */
    }

    #Nhead {
        /* margin-left: 9.57vw; */
        margin-left: 0%;
        margin-top: 57.3vh;
    }

    #Shead {
        /* margin-left: 13.99vw; */
        /* margin-left: 10%; */
        margin-left: 0%;
        display: flex;
        justify-content: flex-end;
    }


    .emilyDickonson {
        width: auto;
        height: 30.75vh;
        margin-left: 41vw;
        /* margin-top: 54.55vh; */
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .mainorangeEmily {
        display: flex;
        width: 100vw;
        position: relative;
        overflow: hidden;
        z-index: -1;
        height: 50vh;
        background-color: #D56D54;
    }

    .orangeEmilyPara {
        width: 94.7vw;
        height: 14.72vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 2.9vh;
        line-height: 30px;
        color: white;
        text-align: center;
        margin-left: 3.81vw;
        margin-top: 14.4vh;
    }

    .womanhideshow {
        display: none !important;
    }


}

@media(min-width:768px) and (max-width:1023px) {
    .BirdLotus {
        /* width: 97.1vw; */
        width: 100%;
        height: 43.13vh;
        margin-left: 39vw;
        margin-top: 17vw;
    }

    .NSheading {
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 26.84%;
        /* letter-spacing: 0.0em; */
        text-transform: capitalize;
        color: #D56D54;
        /* z-index: 6; */
        position: absolute;
    }

    #Nhead {
        margin-left: 9.57vw;
        margin-top: 47.3vh;
    }

    .emilyDickonson {
        width: 46vw;
        height: 34.75vh;
        margin-left: 60vw;
        margin-top: 65.55vh;
        position: absolute;
    }

    .womanhideshow {
        display: none !important;
    }

    .mainorangeEmily {
        display: flex;
        width: 100vw;
        position: relative;
        overflow: hidden;
        z-index: -1;
        height: 50vh;
        background-color: #D56D54;
    }

    .orangeEmilyPara {
        width: 91.7vw;
        height: 14.72vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 2.9vh;
        color: white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
}