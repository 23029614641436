.mainAppleRadio {
    display: flex;
    position: relative;
    width: 73.64vw;
    /* overflow: hidden; */
    z-index: -1;
    height: 100vh;
    background-color: #333333;
}

.courting1 {
    position: absolute;
    width: 45.75vw;
    height: 48.79vh;
    left: -18.3vw;
    top: 10.9vh;
}

.count {
    position: absolute;
    left: 6.17vw;
    top: 47vh;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12.1vh;
    line-height: 192px;
    color: #D56D54;
}

.para {
    position: absolute;
    width: 22.50vw;
    height: 13.51vh;
    left: 12.7vw;
    top: 64vh;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2.5vh;
    line-height: 30px;
    color: #FFFFFF;
}

#c2 {
    left: 34.5vw;
    top: 5vh;
}

#p2 {
    width: 23.50vw;
    left: 43vw;
    top: 22vh;
}

.appleradioimg {
    position: absolute;
    width: 25.79vw;
    height: 50.7vh;
    left: 58vw;
    top: 40vh;
}

.appleradioimg1 {
    position: absolute;
    width: 25.79vw;
    height: 50.7vh;
    left: -15.7vw;
    top: 40.1vh;
}

#c3 {
    left: 10vw;
    top: 52vh;
}

#p3 {
    color: black;
    width: 15.5vw;
    left: 18.1vw;
    top: 69vh;
    line-height: 26px;
}

@media(min-width:280px) and (max-width:767px) {

    .mainAppleRadio {
        display: flex;
        position: relative;
        width: 100vw;
        /* overflow: hidden; */
        z-index: -1;
        height: 95vh;
        background-color: #333333;
    }

    .count {
        position: absolute;
        left: 6.17vw;
        top: 47vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12.1vh;
        line-height: 192px;
        color: #D56D54;
    }

    #c1 {
        left: 7.5vw ! important;
        top: -2vh ! important;
    }


    #p1 {
        width: 80.5vw ! important;
        left: 8vw ! important;
        top: 24vh ! important;
        text-align: left !important;
        color: white !important;
        font-size: 2vh !important;
    }

    #c2 {
        left: 67.5vw ! important;
        top: 33vh ! important;
    }

    #p2 {
        width: 42.5vw ! important;
        left: 52vw ! important;
        top: 61vh ! important;
        color: white !important;
        font-size: 2vh !important;
    }

    .appleradioimg {
        position: absolute;
        width: 56.79vw;
        /* height: 30.7vh; */
        height: auto;
        /* height: fit-content; */
        left: 0vw;
        top: 55%;
    }

    .parasecondLegend {
        text-align: center !important;
    }



}

@media(min-width:768px) and (max-width:1023px) {

    .mainAppleRadio {
        display: flex;
        position: relative;
        width: 100vw;
        /* overflow: hidden; */
        z-index: -1;
        height: 100vh;
        background-color: #333333;
    }

    .count {
        position: absolute;
        left: 6.17vw;
        top: 47vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12.1vh;
        line-height: 192px;
        color: #D56D54;
    }

    #c1 {
        left: 7.5vw ! important;
        top: 10vh ! important;
    }

    #p1 {
        width: 65.5vw ! important;
        left: 8vw ! important;
        top: 33vh ! important;
        text-align: left !important;
        color: white !important;
    }

    #c2 {
        left: 74.5vw ! important;
        top: 51vh ! important;
    }

    #p2 {
        width: 42.5vw ! important;
        left: 52vw ! important;
        top: 75vh ! important;
        color: white !important;
    }

    .appleradioimg {
        position: absolute;
        width: 55.79vw;
        height: 37.7vh;
        left: 0vw;
        top: 58vh;
    }

    .parasecondLegend {
        text-align: center !important;
    }
}