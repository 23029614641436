@media(min-width:280px) and (max-width:767px) {
    .mainTutorGreyMobile {
        position: relative;
        height: 100vh;
        background-color: #F4EACE;
    }

    .mainTutorGreyMobileHeading {
        position: absolute;
        top: 6%;
        left: 7%;
        margin: 0;
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 102%;
        color: black;
        width: 80%;
    }

    .mainTutorGreyMobilefirstpara {
        margin: 0;
        position: absolute;
        top: 26%;
        left: 4%;
        width: 95%;
        /* border: 1px solid; */
    }

    .mainTutorGreyMobilefirstpara p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 2vh;
        line-height: 3vh;
        color: #000000;
    }

    .smallBirdtwobird {
        position: relative;
        top: 50%;
        /* left: 16%; */
        left: calc(50% - 128px);
    }

    .TutorSkinMobilemain {
        position: relative;
        height: 80vh;
        background-color: #F4EACE;
    }

    .TutorSkinMobilemainpara {
        margin: 0;
        position: relative;
        top: 0%;
        left: 8%;
        width: 88%;
    }

    .TutorSkinMobilemainpara p {
        margin: 0;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 2vh;
        line-height: 3vh;
        color: #000000;
    }

    .strokeBluenew {
        width: 100vw;
        height: 48vh;
        position: absolute;
        top: 48%;
        margin-left: 0%;
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .mainTutorGreyMobile {
        position: relative;
        height: 100vh;
        background-color: #F4EACE;
    }

    .mainTutorGreyMobileHeading {
        position: absolute;
        top: 6%;
        left: 7%;
        margin: 0;
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        font-size: 67px;
        line-height: 102%;
        color: black;
        width: 80%;
    }

    .mainTutorGreyMobilefirstpara {
        margin: 0;
        position: absolute;
        top: 29%;
        left: 4%;
        width: 94%;
        /* border: 1px solid; */
    }

    .mainTutorGreyMobilefirstpara p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 2.4vh;
        line-height: 3.1vh;
        color: #000000;
    }

    .smallBirdtwobird {
        position: relative;
        top: 50%;
        left: 21%;
        width: 58%;
    }

    .TutorSkinMobilemain {
        position: relative;
        height: 83vh;
        background-color: #F4EACE;
    }

    .TutorSkinMobilemainpara {
        margin: 0;
        position: relative;
        /* top: 26%; */
        left: 8%;
        width: 88%;
    }

    .TutorSkinMobilemainpara p {
        margin: 0;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 2.4vh;
        line-height: 3.1vh;
        color: #000000;
    }

    .strokeBluenew {
        width: 100vw;
        height: 47vh;
        position: absolute;
        top: 48%;
        margin-left: 0%;
        z-index: 1;
    }

}