@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Averia+Libre&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@700&display=swap");
.LandingScreen {
  background-image: url("../../../assets/images/natures-symphony/game/desktop/landing-screen-bg.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
}

.LandingScreenButton {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 52.74%, #000000 121.72%);
  padding: 12vh 10vh;
  cursor: pointer;
}

.WhiteButton {
  cursor: pointer;
  background-color: #fff;
  box-shadow: 1px 4px 10px 4px rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  border-color: #fff;
  font-family: Poppins;
  font-weight: 400, Regular;
}

#start-game-button{
  width: 45vw;
  font-size: 20px;
  color: #000;
  cursor: pointer;
}
#start-game-button {
  margin-bottom: 6vh;
}

@media only screen and (min-width: 760px) {
  .LandingScreen {
    background-image: url("../../../assets/images/natures-symphony/game/desktop/landing-screen-bg.jpg");
  }
  #start-game-button{
    width: 25vw;
  }
}
