@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.LegendSlider {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}

.LegendLoreContent {
  flex-grow: 1;
  height: 100%;
  margin-right: 4vh;
}

.LegendLoreImage {

  object-fit: cover;
  width: 110%;
  height: 100%;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.LegendLoreButton {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

.LegendLoreAudio {
  position: absolute;
  top: 59%;
  left: 4%;
  background-color: rgba(85, 69, 49, 1);
  font-family: "Poppins", sans-serif;
  font-size: 1.3vh;
  padding: 1.1vh 1.1vw;
  border-radius: 2vh;
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: none;
}

@media screen and (max-width: 1024px) {
  .LegendLoreAudio {
    top: 64%;
  }
}

@media screen and (max-width: 1440px) {
  .LegendLoreAudio {
    top: 62%;
  }
}