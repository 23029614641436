@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Averia&display=swap");

/* Slide Styles */

.BirdsInMusicSlider {
  height: 100vh;
  width: 1300vw;
  overflow: hidden;

}

.BirdsInMusicInner {
  width: 100%;
  height: 100%;
  display: flex;
}

.BirdsInMusicSlideColumns {
  display: flex;
  justify-content: center;
  height: 100%;
}

.BirdsInMusicSlide {
  width: 100%;
  height: 100%;
}

.BirdsInMusicRightArrowFadeIn {
  position: absolute;
  top: 50%;
  width: 5vh;
  right: 5%;
  animation-name: fadeIn;
  animation-duration: 1s;
}

.BirdsInMusicLeftArrowFadeIn {
  position: absolute;
  top: 50%;
  width: 5vh;
  left: 5%;
  transform: rotate(180deg);
  animation-name: fadeIn;
  animation-duration: 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: 0.3;
  }

  60% {
    opacity: 0.5;
  }

  80% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

.BirdsInMusicRightArrowFadeOut {
  position: absolute;
  top: 50%;
  width: 5vh;
  right: 5%;
  opacity: 0;
  animation-name: fadeOut;
  animation-duration: 1s;
}

.BirdsInMusicLeftArrowFadeOut {
  position: absolute;
  top: 50%;
  width: 5vh;
  left: 5%;
  opacity: 0;
  transform: rotate(180deg);
  animation-name: fadeOut;
  animation-duration: 1s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  40% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.5;
  }

  80% {
    opacity: 0.3;
  }

  100% {
    opacity: 0;
  }
}

/* Slide Header Column Styles */

.BirdsInMusicHeaderColumn {
  height: 100%;
  width: 50%;
  padding-left: 10%;
  position: relative;
}

.BirdsInMusicHeaderColumnBlack {
  height: 100%;
  width: 50%;
  padding-left: 10%;
  position: relative;
  color: black;
}


.BirdsInMusicTextBackgroundLarge {
  height: 100%;
  position: absolute;
}

.BirdsInMusicSlideHeader {
  position: absolute;
  top: 4%;
  left: 30%;
  width: 32%;
  font-size: 4.5vh;
  z-index: 100;
}

.BirdsInMusicSlideHeaderImg {
  width: 55vh;
  position: absolute;
  top: 40%;
  left: 28%;
}

/* Slide Content Column Styles */

.BirdsInMusicContentColumn {
  height: 100%;
  width: 50%;
  padding-left: 10%;
  position: relative;
}

.BirdsInMusicContentColumnBlack {
  height: 100%;
  width: 50%;
  padding-left: 10%;
  position: relative;
  color: black;
}

.BirdsInMusicTextBackgroundSmall {
  height: 70%;
  top: 15%;
  position: absolute;
}

.BirdsInMusicContentWrapper {
  position: absolute;
  width: 30vh;
  top: 22%;
  left: 30%;
  z-index: 100;
}
.BirdsInMusicContentWrappertwo{

  position: absolute;
      width: 53vh;
      top: 20%;
      left: 30%;
      z-index: 100;
      
}

.BirdsInMusicContent {
  font-size: 1.8vh;

}

/* .BirdsInMusicAudioSection{
  margin-top:10%;
  z-index: 100;
}
.BirdsInMusicAudioSectionLower{
  margin-top:40%;
  z-index: 100;
} */

.BirdsInMusicPlayButton {
  width: 10px;
  margin-right: 5px;
}

.BirdsInMusicAudioButton p {
  margin-top: 2px;
  margin-bottom: 2px;
}

.BirdsInMusicAudioButton {
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #F4EACE;
  padding: 0px 10px 0px 10px;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 20px;
  cursor: pointer;
  color: black;

}

.BirdsInMusicAudioDescription {
  font-weight: lighter;
  font-size: 1.4vh;
}

.BirdsInMusicAudioCredits {
  font-weight: lighter;
  font-size: 1.4vh;
}

.BirdsInMusicVideoButton p {
  margin-top: 2px;
  margin-bottom: 2px;
}

.BirdsInMusicVideoButton {
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #F4EACE;
  padding: 0px 10px 0px 10px;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 20px;
  cursor: pointer;
  color: black;

}

.BirdsInMusicVideoDescription {
  font-weight: lighter;
  font-size: 1.4vh;
}

.BirdsInMusicVideoCredits {
  font-weight: lighter;
  font-size: 1.4vh;
}

/* .BirdsInMusicSlideContentImg{
  width: 40vh;
  position:absolute;
  bottom:15%;
  left: 28%;
} */


/* Landing Page Styles */
.BirdsInMusicLandingPage {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  width: 100%;
  height: 100%;
  position: relative;

}

.BirdsInMusicLandingPageTitle {
  font-size: 200px;
  font-weight: 400;
  position: absolute;
  top: 18vh;
  left: 8%;
  z-index: 100;
}

.BirdsInMusicButton {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  
}



.BirdsInMusicLandingPageImg1 {
  width: 900px;
  position: absolute;
  left: 35%;
  bottom: 0;
}

.BirdsInMusicLandingPageImg2 {
  width: 300px;
  position: absolute;
  left: 18%;

}

/* Intro Slide Styles */
.BirdsInMusicSlide2 {
  width: 100%;
  height: 100%;
  /* padding-top: 1%; */
}


.BirdsInMusicSlide2Header {
  position: relative;
  top: 35%;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;

}


.BirdsInMusicSlide2Header>p {
  width: 900px;
  text-align: center;
  /* font-family: 'Poppins' !important; */
  /* font-family: 'Averia Libre, cursive' !important; */
  font-style: normal;
}

.desktopBirdsInMusic {
  display: block !important;
}

.MobileBirdsInMusic {
  display: none !important;
}

.Birdsin{
   margin-right: 8%;
}

@media screen and (min-width: 280px) and (max-width: 767px) {

  .desktopBirdsInMusic {
    display: none !important;
  }

  .MobileBirdsInMusic {
    display: block !important;
  }

  .birdsinmusicslider {
    height: 75vh !important;
    /* border: 1px solid red; */
  }

  .Birdsin {
    font-size: 36px;
   margin-right: 0%;
  }

  .imghidemobile {
    display: none ! important;
  }

  .BirdsInMusicLandingPageTitle {
    font-size: 59px;
    font-weight: 400;
    position: absolute;
    top: 26vh;
    left: 15%;
    z-index: 18;
  }

  .BirdsInMusicLandingPageImg2 {
    width: 209px;
    position: absolute;
    top: 17%;
    left: 43%;

  }

  /*============== BirdsInMusicSlide2============== */
  .BirdsInMusicSlide2 {
    width: 100%;
    height: 61%;
    /* padding-top: 1%; */
  }

  .BirdsInMusicSlide2Header {
    position: relative;
    top: -40%;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .BirdsInMusicSlide2Header>p {
    width: 78%;
  }

}


@media screen and (min-width: 768px) and (max-width: 1023px) {

  .desktopBirdsInMusic {
    display: none !important;
  }

  .MobileBirdsInMusic {
    display: block !important;
  }

  .Birdsin {
    font-size: 83px;
    margin-right: 0%;
  }
  
  .birdsLandingPageImg2 {
    margin-left: 16% !important;
  }

  .imghidemobile {
    display: none ! important;
  }

  .BirdsInMusicLandingPageTitle {
    font-size: 126px;
    font-weight: 400;
    position: absolute;
    top: 46vh;
    left: 14%;
    z-index: 18;
  }

  .BirdsInMusicLandingPageImg2 {
    width: 404px;
    position: absolute;
    top: 29%;
    left: 38%;

  }


  /*============== BirdsInMusicSlide2============== */
  .BirdsInMusicSlide2 {
    width: 100%;
    height: 50%;
    /* padding-top: 1%; */
  }


  .BirdsInMusicSlide2Header {
    position: relative;
    top: -30%;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

  }


  .BirdsInMusicSlide2Header>p {
    /* width: 900px;
    text-align: center; */

    width: 80%;
    /*  text-align: center;
      font-size: 25px; */
  }
}

@media(min-width:1279px) and (max-width:1281px){
  .BirdsInMusicContentWrappertwo{
  
    position: absolute;
        width: 65vh;
        top: 20%;
        left: 30%;
        z-index: 100;
        
  }

}
@media(min-width:1535px) and (max-width:1538px){
  .BirdsInMusicContentWrappertwo{
  
    position: absolute;
        width: 65vh;
        top: 20%;
        left: 30%;
        z-index: 100;
        
  }

}
@media(min-width:1365px) and (max-width:1368px){
  .BirdsInMusicContentWrappertwo{
  
    position: absolute;
        width: 65vh;
        top: 20%;
        left: 30%;
        z-index: 100;
        
    }
}


