@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
#bird-image {
  background-position: center;
  background-color: rgba(1, 1, 1, 1);
  background-size: contain;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#bino-image {
  position: relative;
  left: 76vw;
  top: 20vh;
}
#son-name {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  left: 76vw;
  top: 22vh;
  width: 15vw;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 2vh;
}
#son-name::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 3px solid rgba(153, 106, 56, 1);
  width: 70%;
}
#son-desc {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  left: 76vw;
  top: 22vh;
  width: 14vw;
}
.son-audio {
  background: rgba(119, 71, 22, 1);
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  top: 23vh;
  left: 76vw;
  padding: 1.5vh 1vw;
  width: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* justify-content: space-between; */
  cursor: pointer;
  border: none;
}

#son-snapper {
  position: absolute;
  bottom: 9vh;
  left: 14.5vw;
  color: rgba(206, 134, 63, 1);
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
#son-navigation {
  position: absolute;
  bottom: 7vh;
  left: 39.5vw;
  display: flex;
  flex-direction: row;
}
#son-navigation:hover {
  cursor: pointer;
}
#son-id {
  color: rgba(206, 134, 63, 1);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
  cursor: default !important;
}
#son-close-button {
  color: rgba(153, 106, 56, 1);
  position: relative;
  float: right;
  top: 6vh;
  right: 3vw;
  cursor: pointer;
}
