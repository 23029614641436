.mainTutorGrey {
  display: flex;
  width: 60.17vw;
  position: relative;
  overflow: hidden;
  z-index: -1;
  height: 100vh;
  background-color: #333333;
}

.mainTutorSkin {
  display: flex;
  width: 100.1vw;
  position: relative;
  overflow: hidden;
  z-index: -1;
  height: 100vh;
  background-color: #F4EACE;
}

.TutorHeading {

  position: absolute;
  width: 40.19vw;
  height: 49.62vh;
  left: 6.74vw;
  top: 23.79vh;

  font-family: 'Averia Libre';
  font-style: normal;
  font-weight: 700;
  font-size: 11.6vh;
  line-height: 102%;
  color: #FFFFFF;

}

.smallBird {
  position: absolute;
  width: 7vw;
  height: 28vh;
  left: 81.77%;
  right: 79.85%;
  top: 46.24%;
  bottom: 21.54%;

}

.smallBirdLayer {
  position: absolute;
  width: 13vw;
  height: 26vh;
  left: 80.5%;
  right: 71.29%;
  top: 22.87%;
  bottom: 52.5%;
  animation: fadeIn 3s;

}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.bigBird {
  position: absolute;
  width: 12.08vw;
  height: 55.17vh;
  left: 17.6%;
  right: 0%;
  top: 29.82%;
  bottom: 0%;
}

.bigBirdLayer {
  position: absolute;
  width: 17.07vw;
  height: 32.67vh;
  left: .01%;
  right: 12.8%;
  top: 14%;
  bottom: 49.94%;

}

#skinSmallBirdLayer {
  left: -11.7%
}

.TutorPara {
  position: absolute;
  width: 26.58vw;
  height: 16.38vh;
  left: 33vw;
  top: 25.24vh;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 2.4vh;
  line-height: 3.1vh;
  color: #000000;

}

#TutorPara2 {
  top: 48.87vh;
}

#tutorVideoButton {
  margin-left: 33vw;
  margin-top: 78vh;
}

#strokeBlueTutor {
  margin-left: 66.3vw;
  width: 37vw;
}

#factoidLotusTutor {
  margin-left: 71.3vw;
}

.factoidHeadingTutor {
  margin-left: 76.65vw;
  position: absolute;
  height: 11.57vh;
  margin-top: 39.94vh;
  z-index: 100;
  width: 15.66;
}

#factoidParaTutor {
  margin-left: 76vw;
  width: 19.3vw;
  height: 25.09vh;
  margin-top: 54vh;
  font-size: 2.5vh;
  position: absolute;
  line-height: 3.4vh;
  text-align: center;
}

@media(min-width:280px) and (max-width:767px) {

  .mainTutorGrey {
    display: flex;
    width: 100vw ! important;
    position: relative;
    overflow: hidden;
    z-index: -1;
    height: 70vh;
    background-color: #F4EACE;
    /* border: 1px solid red; */
  }

  .mainTutorSkin {
    /* border: 1px solid red; */
    height: 110vh;
  }

  #strokeBlueTutor22 {
    margin-top: 2%;
    height: 55vh;
  }

  #factoidParaTutor {
    margin-left: 4vw;
    width: 93vw;
    height: 25.09vh;
    margin-top: 34vh;
    font-size: 2vh;
    position: absolute;
    line-height: 3vh;
    text-align: center;
  }

  .factoidHeadingTutor {
    margin-left: 12.65vw;
    position: absolute;
    height: 11.57vh;
    margin-top: 81.94vh;
    z-index: 100;
    width: 15.66;
  }

  .TutorHeading {
    position: absolute;
    width: 96.19vw;
    height: 49.62vh;
    left: 6.74vw;
    top: 8.79vh;
    font-family: 'Averia Libre';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 102%;
    color: black;
  }

  .mobilehideshowimg {
    display: block !important;
    z-index: -1;
  }

  .descohideshowimg {
    display: none !important;
  }

  .smallBird {
    position: absolute;
    /* width: 76vw; */
    width: auto !important;
    /* height: 46vh; */
    height: auto !important;
    left: 20.77%;
    /* right: 86.85%; */
    top: 38.24%;
    /* bottom: 21.54%; */
  }

  #TutorPara2 {
    top: 17.87vh;
  }

  .TutorPara {
    position: absolute;
    width: 95.58vw;
    height: 16.38vh;
    left: 4vw;
    /* top: 1.24vh; */
    top: 0%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2vh;
    line-height: 3vh;
    color: #000000;
  }

  #tutorVideoButton {
    /* margin-left: 4vw;
    margin-top: 43vh; */
    margin-left: 9vw;
    margin-top: 3vh;
  }

  .mobilehideshowimg {
    display: block !important;
    z-index: -1;
    /* height: auto !important; */
  }

  .descohideshowimg {
    display: none !important;
  }

  #factoidLotusTutor {
    margin-left: 59.3vw;
    margin-top: -5%;
    /* width: 68% !important; */
    width: 62%;
    /* height: fit-content; */
    height: auto;
  }
}

@media(min-width:768px) and (max-width:1023px) {

  .mobilehideshowimg {
    display: block !important;
    z-index: -1;
  }

  .descohideshowimg {
    display: none !important;
  }

  .mainTutorGrey {
    display: flex;
    width: 100vw ! important;
    position: relative;
    overflow: hidden;
    z-index: -1;
    height: 100vh;
    background-color: #F4EACE;
  }

  .TutorHeading {
    position: absolute;
    width: 96.19vw;
    height: 49.62vh;
    left: 6.74vw;
    top: 14.79vh;
    font-family: 'Averia Libre';
    font-style: normal;
    font-weight: 700;
    font-size: 67px;
    line-height: 102%;
    color: black !important;
    ;
  }

  .smallBird {
    position: absolute;
    width: 62vw;
    height: 46vh;
    left: 22.77%;
    /* right: 86.85%; */
    top: 42.24%;
    /* bottom: 21.54%; */
  }

  #TutorPara2 {
    top: 18.87vh;
  }

  .TutorPara {
    position: absolute;
    width: 90.58vw;
    height: 16.38vh;
    left: 4vw;
    top: 1.24vh;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2.4vh;
    line-height: 3.1vh;
    color: #000000;
  }

  #tutorVideoButton {
    /* margin-left: 4vw;
    margin-top: 43vh; */
    margin-left: 8vw;
    margin-top: 8vh;
  }

  .mobilehideshowimg {
    display: block !important;
    z-index: -1;
  }

  .descohideshowimg {
    display: none !important;
  }

  #factoidParaTutor {
    margin-left: 4vw;
    width: 92.3vw;
    height: 25.09vh;
    margin-top: 33vh;
    font-size: 2.5vh;
    position: absolute;
    line-height: 3.4vh;
    text-align: center;
    z-index: 1;
  }

  .factoidHeadingTutor {
    margin-left: 24.65vw;
    position: absolute;
    height: 11.57vh;
    margin-top: 64.94vh;
    z-index: 100;
    width: 15.66;
  }

  #factoidLotusTutor {
    margin-left: 72.3vw;
    width: 50%;
    z-index: 1;
  }
}

@media(min-width:1023px) and (max-width:1300px) {
  .TutorHeading{
      font-size: 9.6vh;
  }
}