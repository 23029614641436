@import url("https://fonts.googleapis.com/css2?family=Averia+Libre&display=swap");

body {
  font-family: "Averia Libre", cursive;
  color: white;
}

#pg-three {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url("../../assets/images/landing-page/screen-three33.jpg");
  background-size: cover;
}

#content-three {
  font-size: 2.1vw;
  width: 23vw;
  height: 17vh;
  position: fixed;
  top: 33vh;
  right: 17vw;
  text-align: center;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  #content-three {
    font-size: 8vw;
    width: 90% !important;
    top: 25vh;
    right: 0vw;
  }
  
#pg-three {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url("../../assets/images/landing-page/screen-three.jpg");
  background-size: cover;
}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .skip {
    font-size: 5.1vw;
  }

  #content-three {
    font-size: 7vw;
    width: 90% !important;
    top: 5vh;
    text-align: right !important;
    right: 3.3vw ! important;
  }
  
#pg-three {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url("../../assets/images/landing-page/screen-three.jpg");
  background-size: cover;
}
}