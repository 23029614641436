.mainSkinEar {
    display: flex;
    width: 84vw;
    position: relative;
    overflow: hidden;
    z-index: -1;
    height: 100vh;
    background-color: #F4EACE;
}

.skinEarHeading {
    position: absolute;
    font-family: 'Averia Libre';
    font-style: normal;
    font-weight: 700;
    line-height: 101%;
    color: #000000;
    width: 55.5vw;
    height: 49.62vh;
    left: 18.99vw;
    top: 21.49vh;
    font-size: 11.6vh;
}

.skinEarPara {
    position: absolute;
    width: 30.48vw;
    height: 19.44vh;
    left: 18.99vw;
    top: 62vh;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2.6vh;
    line-height: 3.4vh;
    color: #000000;
}

.skinEarimg {

    position: absolute;
    width: 54vw;
    height: 90vh;
    left: 48.6vw;
    top: 4vh;

}

#skinEarimg2 {
    left: -35.5vw;
}

@media(min-width:280px) and (max-width:767px) {
    .mainSkinEar {
        display: flex;
        width: 100vw;
        position: relative;
        top: -15px;
        overflow: hidden;
        z-index: -1;
        height: 120vh;
        background-color: #F4EACE;
    }

    #skinstone {
        display: block;
        position: absolute;
        top: -23% !important;
        left: 36% !important;
        width: 58vw ! important;
        /* height: 61.75vh ! important; */
        /* height: fit-content; */
        height: auto;
    }

    #skinwl {
        display: block;
        position: absolute;
        top: -24% !important;
        left: 42% !important;
        width: 53.54vw vw ! important;
        /* height: 61.33vh ! important; */
        /* height: fit-content; */
        height: auto;
    }

    #skinbl {
        display: block;
        position: absolute;
        left: 43.14%;
        top: -21.02%;
        width: 46.46vw;
        /* height: 45.9vh; */
        /* height: fit-content; */
        height: auto;
    }

    .skinEarHeading {
        position: absolute;
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        line-height: 101%;
        color: #000000;
        width: 80vw;
        /* height: 49.62vh; */
        height: auto !important;
        left: 9.99vw;
        top: 18.49vh;
        font-size: 36px;
    }

    .skinEarPara {
        position: absolute;
        width: 85.48vw;
        height: 19.44vh;
        left: 10.99vw;
        top: 44vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 2vh;
        line-height: 3vh;
        color: #000000;
    }

    .skinEarimg {
        position: absolute;
        /* width: 68vw; */
        width: auto !important;
        height: 40vh;
        /* left: 27.6vw; */
        left: calc(50% - 105px);
        top: 80vh;
    }
}

@media(min-width:768px) and (max-width:1023px) {

    .mainSkinEar {
        display: flex;
        width: 100vw;
        position: relative;
        top: -26px;
        overflow: hidden;
        z-index: -1;
        height: 120vh;
        background-color: #F4EACE;
    }

    #skinstone {
        display: block;
        position: absolute;
        top: -29% !important;
        left: 36% !important;
        width: 58vw ! important;
        height: 61.75vh ! important;
    }

    #skinwl {
        display: block;
        position: absolute;
        top: -36% !important;
        left: 42% !important;
        width: 53.54vw vw ! important;
        height: 61.33vh ! important;
    }

    #skinbl {
        display: block;
        position: absolute;
        left: 43.14%;
        top: -28.02%;
        width: 46.46vw;
        height: 45.9vh;
    }

    .skinEarHeading {
        position: absolute;
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        line-height: 101%;
        color: #000000;
        width: 90vw;
        height: 49.62vh;
        left: 9.99vw;
        top: 24.49vh;
        font-size: 64px;
    }

    .skinEarPara {
        position: absolute;
        width: 87.48vw;
        height: 19.44vh;
        left: 10.99vw;
        top: 43vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 2.6vh;
        line-height: 3.4vh;
        color: #000000;
    }

    .skinEarimg {
        position: absolute;
        /* width: 68vw; */
        width: auto !important;
        /* height: 52vh; */
        height: auto !important;
        left: calc(50% - 105px);
        top: 81vh;
        /* left: 27.6vw;
        top: 72vh; */
    }
}

@media(min-width:1023px) and (max-width:1300px) {
    .skinEarHeading{
        font-size: 10.6vh;
    }
}