@import url("https://fonts.googleapis.com/css2?family=Averia+Libre:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

#songs-of-nature {
  width: 100vw;
  height: 100vh;

  margin: 0;
  overflow: hidden;
}

#son-parent-one-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-image: url("../../assets/images/songs-of-nature/songs-of-nature-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#son-parent-one {
  display: flex;
  align-items: center;
  z-index: 10;
}

#son-main {
  display: flex;
  align-items: center;
}

#son-one {
  display: inline-block;
  height: 100vh;
  padding: 25vh 23vw;
}

.son-heading {
  font-family: "Averia Libre", cursive;
  line-height: 70%;
  font-weight: bold;
  color: white;
}

#songs {
  font-size: 30vh;
}

#of {
  font-size: 20vh;
}

#nature {
  font-size: 30vh;
  margin: 0;
}

#son-two {
  font-family: Poppins;
  font-size: 3.1vh;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.04em;
  text-align: center;
  margin: 0vh 40vw 0vh 20vw;
  width: 45vw;
}

#son-parent-two {
  position: relative;
  width: 101vw;
  height: 100vh;
}

#son-nav,
#binocular,
#bino-button {
  position: absolute;
  z-index: 1;
}

#binocular,
#bino-button {
  cursor: pointer;
}

#binocular {
  bottom: 13vh;
  left: 54vw;
}

#bino-button {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.04em;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  bottom: 6vh;
  left: 51.5vw;
}

#img-map {
  width: 100%;
  height: 100%;
}

.desktopshowsongsofnature {
  display: block !important;
  width: max-content;
}

.mobileshowsongsofnature {
  display: none !important;
}

/* onlay  birds  */
.MalabarWhistling {
  position: absolute;
  top: 3% !important;
  left: 26% !important;
  width: 21%;
  height: 50%;
  /* border: 1px solid red; */
}

.HimalayanThrush {
  position: absolute;
  top: 52% !important;
  right: 5% !important;
  width: 25%;
  height: 50%;
}

.RedWhiskered {
  position: absolute;
  top: 38% !important;
  right: 32% !important;
  width: 17%;
  height: 33%;
  /* border: 1px solid red; */
  border-radius: 0 50rem 0 0;
}

.PurpleSunbird {
  position: absolute;
  top: 30% !important;
  right: 16% !important;
  width: 20%;
  height: 40%;
  /* border: 1px solid red; */
  border-radius: 0 0 50rem 0;
}

.RustyBellied {
  position: absolute;
  top: 51% !important;
  left: 32% !important;
  width: 20%;
  height: 40%;

}

.WhiteRumped {
  position: absolute;
  top: 31% !important;
  left: 2% !important;
  width: 25%;
  height: 51%;
}

.GreyWinged {
  position: absolute;
  top: 40% !important;
  left: 10% !important;
  width: 22%;
  height: 58%;
  /* border: 1px solid red; */
  border-radius: 50rem 0px 0px 0px;
}

.PuffThroated {
  position: absolute;
  top: 3% !important;
  right: 4% !important;
  width: 16%;
  height: 39%;
}

.OrientalMagpie {
  position: absolute;
  top: 5% !important;
  left: 10% !important;
  width: 16%;
  height: 31%;
}

.Asiankoel {
  position: absolute;
  top: 2% !important;
  left: 50% !important;
  width: 23%;
  height: 42%;
  /* border: 1px solid red; */
  border-radius: 0 0 50rem;
}

@media screen and (min-width: 280px) and (max-width: 767px) {

  #son-nav {
    position: absolute;
    z-index: 1;
    top: -2%;
  }

  #son-parent-one-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-image: url("../../assets/images/songs-of-nature/bg_fullsize.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .desktopshowsongsofnature {
    display: none !important;
  }

  .mobileshowsongsofnature {
    display: block !important;
    display: flex;
    flex-direction: column;
  }

  #songs-of-nature {
    overflow-y: scroll !important;
  }

  #son-parent-one {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    z-index: 10;
    /* border: 1px solid red; */
    height: 100vh !important;
  }

  #songs {
    font-size: 7vh;
  }

  #of {
    font-size: 6vh;
  }

  #nature {
    font-size: 10vh;
    margin: 0;
  }

  #son-one {
    display: flex;
    align-items: center;
    height: 43vh;
    padding: 27vh 0vh 8vh 0vh;
  }

  #son-two {
    width: 100vw;
    /* border: 1px  solid red; */
    margin: 0vh 0vw 0vh 0vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #son-two p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.04em;
    text-align: center;
    width: 80vw;
  }

  /* ===========BirdsectionMobile===================== */

  .BirdsectionMobileMain {
    /* min-height: 60vh;  */
    min-height: auto !important;
    width: 100vw;
    margin: 20% auto;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
  }

  .BirdsectionMobileMain .Birdcolorsection {
    width: 100% !important;
    background-color: black;
    /* min-height: 70vh; */
    min-height: auto !important;
    margin: auto;
    text-align: center;
    box-shadow: 0px 0px 20px 8px black;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain {
    /* min-height: 70vh; */
    min-height: auto !important;
    /* border: 1px solid; */
    margin: 4% auto;
    width: 90%;
    text-align: center;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #CE863F;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .Birdsecondfirst {
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondSecond {
    display: flex;
    align-items: center;
    margin-top: 3%;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondSecond .camera {
    width: 8%;
    /* background-color: red; */
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondSecond .camera img {
    width: 100% !important;
    height: 100% !important;
    /* background-color: red; */
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondSecond .cameraNameText {
    margin-left: 2%;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondSecond .cameraNameText h2 {
    border: 0px solid red;
    text-align: left;
    color: #CE863F;
    font-size: 13px;
  }


  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondThird {
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    color: #CE863F;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondThird p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    text-align: center;
    width: 85%;
    margin: 0 auto;
    font-size: 15px;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondThird .audioPlayPauseButton {
    background: #774716;
    border-radius: 10px;
    color: white;
    padding: 10px 10px;
    border: none !important;
    /* margin: 0 5%; */
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondThird .audioPlayPauseButton b {

    font-size: 16px !important;
  }

  .playpuse {
    font-size: 16px !important;
    color: white !important;
  }
}



@media screen and (min-width: 768px) and (max-width: 1023px) {
  #son-parent-one-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-image: url("../../assets/images/songs-of-nature/bg_fullsize.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .desktopshowsongsofnature {
    display: none !important;
  }

  .mobileshowsongsofnature {
    display: block !important;
  }

  #songs-of-nature {
    overflow-y: scroll !important;
  }

  #son-parent-one {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    z-index: 10;
    /* border: 1px solid red; */
    height: 100vh !important;
  }

  #songs {
    font-size: 13vh;
  }

  #of {
    font-size: 8vh;
  }

  #nature {
    font-size: 16vh;
    margin: 0;
  }

  #son-one {
    display: flex;
    align-items: center;
    height: 60vh;
    padding: 11vh 0vh 0vh 0vh;
  }

  #son-two {
    width: 100vw;
    /* border: 1px  solid red; */
    margin: 0vh 0vw 0vh 0vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #son-two p {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.04em;
    text-align: center;
    width: 80vw;
  }

  /* ===========BirdsectionMobile===================== */

  .BirdsectionMobileMain {
    min-height: 80vh;
    /* margin: 20% auto 0% auto; */
    margin: 12% auto;
    width: 100vw;
    border: 0px solid blue;
    display: flex;
    flex-direction: column;
  }

  .BirdsectionMobileMain .Birdcolorsection {
    width: 100% !important;
    background-color: black;
    min-height: 70vh;
    margin: auto;
    text-align: center;
    box-shadow: 0px 0px 44px 8px black;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain {
    min-height: 70vh;
    /* border: 1px solid; */
    margin: 4% auto;
    width: 85%;
    text-align: center;
  }


  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    color: #CE863F;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .Birdsecondfirst {
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondSecond {
    display: flex;
    align-items: center;
    margin-top: 3%;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondSecond .camera {
    width: 10%;
    /* background-color: red; */
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondSecond .cameraNameText h2 {
    border: 0px solid red;
    text-align: left;
    color: #CE863F;
    font-size: 30px;
  }


  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondThird {
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    color: #CE863F;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondThird p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    text-align: center;
    width: 85%;
    margin: 0 auto;
    font-size: 22px;
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondThird .audioPlayPauseButton {
    /* display: flex; */
    /* align-items: center; */
    background: #774716;
    border-radius: 10px;
    color: white;
    padding: 15px 10px;
    border: none !important;
    /* margin: 0 5%; */
  }

  .BirdsectionMobileMain .Birdcolorsection .Birdfirstmain .BirdsecondThird .audioPlayPauseButton b {

    font-size: 24px !important;
  }

  .playpuse {
    font-size: 24px !important;
    color: white !important;
  }

}