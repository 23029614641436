#pg-five,
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-size: cover;
}
#pg-five {
  overflow: hidden;
  background-image: url("../../assets/images/landing-page/screen-four.jpg");
}
.overlay {
  display: flex;
  flex-direction: row;
  background: linear-gradient(
    179.95deg,
    rgba(35, 37, 24, 0.12) 0.04%,
    #23291e 87.47%
  );
}
#identity-overlay {
  justify-content: center;
  align-items: center;
}
