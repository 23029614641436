.loreshowhideDesktop {
    display: block !important;
    margin-left: 54vh;

}

.loreshowhideMobile {
    display: none !important;
}


@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.mainsection {
    /* border: 1px solid red !important; */
    background-image: url("../../assets/images/lore-and-legend/background.png");
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    height: 100vh;
    position: fixed;
    width: 100%;
}

.mainsection2 {
    /* border: 1px solid red !important; */
    background: transparent;
    /* background-image: url("../../assets/images/lore-and-legend/background.png"); */
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    height: 100vh;
    position: relative;
    /* width: 100%; */
}

.legendloremenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px 0px 20px;
    /* border: 1px solid yellow; */
    position: fixed;
    width: 100%;
    z-index: 1;
}

.LegendLoreButton {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
}

.LegendLoreimges {
    display: flex;
    /* border: 1px solid blueviolet; */
    width: 80%;
    margin: 0% auto;
    align-items: center;
    position: relative;
    top: 35%;
}

.firstLegend {
    width: 50%;
    /* border: 1px solid blueviolet; */
    box-sizing: border-box;
}

.secondLegend {
    width: 50%;
    /* background-color: red; */
    /* border: 1px solid blueviolet; */
    box-sizing: border-box;
    padding-left: 5%;
}

.parasecondLegend {
    font-size: 14px;
    font-family: Poppins;
    font-weight: 600;
    /* line-height: 30.84px; */
    letter-spacing: 4%;
    color: #462F16;
    width: 90%;
}

.secondmainsection {
    background-image: url("../../assets/images/lore-and-legend/background.png");
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    height: 100vh;

}

.secondfirstmainsection {
    background-image: url("../../assets/images/lore-and-legend/HandmadePaper_Texture_Wide 2.png");
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    height: 60%;
    width: 70%;
    margin: auto;
    position: relative;
    top: 22%;
    display: flex;
    /* height: 100vh; */
}

.conatantsection {
    /* border: 1px solid red; */
    width: 60%;
    padding: 80px 40px;
}

.conatantimagesection {
    /* border: 1px solid red; */
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.conatantimagesection img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.titlefont {
    font-family: Averia Libre;
    font-weight: 700;
    font-size: 45px;
    line-height: 63.9px;
    color: #554531;
}

.titlepara {
    font-family: Poppins;
    font-size: 16px;
    /* line-height: 31.24px; */
    font-weight: 400;
    color: #554531;
    height: 60%;
    width: 80%;
    /* text-align: justify; */
    margin-top: 5%;
    /* border: 1px solid red; */
}

.LegendLoreAudio {
    background-color: rgba(85, 69, 49, 1);
    font-family: "Poppins", sans-serif;
    font-size: 1.3vh;
    padding: 1.1vh 1.1vw;
    border-radius: 2vh;
    color: white;
    text-decoration: none;
    cursor: pointer;
    border: none;
    display: inline-block;
    /* position: absolute; */
    /* bottom: 36px; */
}

/* ============== static card ================= */
@media screen and (min-width: 280px) and (max-width: 767px) {

    .lorcardmainsection {
        height: auto;
        /* border: 1px solid red; */
        position: relative;
        top: 0;
        left: 0;
        text-align: center;
        padding-bottom: 14%;
    }

    .card-heading {
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 142%;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: #554531;
        margin: 10px auto;
    }

    .lorcardmainsection .card-main-section {
        height: 90%;
        width: 90% !important;
        margin: 0 auto !important;
        /* border: 1px solid blue; */
        background-image: url("../../assets/images/lore-and-legend/HandmadePaper_Texture_Wide 2.png");
        background-repeat: no-repeat;
        padding-bottom: 20px;
        /* background-size: 100vw 100vh; */
    }

    .lorcardmainsection .card-main-section .card-img-section {
        height: 100% !important;
        width: 90% !important;
        margin: 0 auto !important;
        padding: 30px 21px 10px 21px;
    }

    .lorcardmainsection .card-main-section .card-img-section img {
        width: 100%;
        height: 100%;
    }

    .lorcardmainsection .card-main-section .card-contant-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .lorcardmainsection .card-main-section .card-contant-section .card-para {
        margin: 0 auto;
        padding-bottom: 10%;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        color: #554531;
        width: 78%;
        font-size: 12px;
        line-height: 142%;
        text-align: left;
    }

    .lorcardmainsection .card-main-section .card-contant-section .card-LegendLore-Audio {
        background-color: rgba(85, 69, 49, 1);
        font-family: "Poppins", sans-serif;
        font-size: 1.3vh;
        padding: 1.1vh 1.1vw;
        border-radius: 3vh;
        color: white;
        text-decoration: none;
        cursor: pointer;
        border: none;
        /* display: inline-block; */
        display: flex;
        justify-content: center;
        /* position: absolute; */
        /* bottom: 36px; */
        width: 45%;
    }
}


@media screen and (min-width: 768px) and (max-width: 1023px) {
    .lorcardmainsection {
        height: auto;
        /* border: 1px solid red; */
        position: relative;
        top: 0;
        left: 0;
        text-align: center;
        padding-bottom: 14%;
    }

    .card-heading {
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        font-size: 54px;
        line-height: 142%;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: #554531;
        margin: 10px auto;
    }

    .lorcardmainsection .card-main-section {
        height: 90%;
        /* width: 90% !important; */
        width: 70%;
        margin: 0 auto !important;
        /* border: 1px solid blue; */
        background-image: url("../../assets/images/lore-and-legend/HandmadePaper_Texture_Wide 2.png");
        background-repeat: no-repeat;
        padding-bottom: 50px;
        /* background-size: 100vw 100vh; */
    }

    .lorcardmainsection .card-main-section .card-img-section {
        height: 100% !important;
        width: 90% !important;
        margin: 0 auto !important;
        padding: 30px 21px 40px 21px;
    }

    .lorcardmainsection .card-main-section .card-img-section img {
        width: 90%;
        height: 100%;
    }

    .lorcardmainsection .card-main-section .card-contant-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .lorcardmainsection .card-main-section .card-contant-section .card-para {
        margin: 0 auto;
        padding-bottom: 7%;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        color: #554531;
        width: 70%;
        line-height: 142%;
        text-align: center;
    }

    .lorcardmainsection .card-main-section .card-contant-section .card-LegendLore-Audio {
        background-color: rgba(85, 69, 49, 1);
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        padding: 1.1vh 0vw;
        border-radius: 3vh;
        color: white;
        text-decoration: none;
        cursor: pointer;
        border: none;
        display: inline-block;
        /* position: absolute; */
        /* bottom: 36px; */
        width: 40%;
    }

}

/* ============== static card ================= */




@media (max-width: 575px) {

    .LegendLoreimges {
        display: flex;
        flex-direction: column;
        /* border: 1px solid blueviolet; */
        width: 90%;
        margin: 0% auto;
    }

    .firstLegend {
        width: 90%;
        /* border: 1px solid blueviolet; */
        box-sizing: border-box;
        margin: 0 auto;
        margin-left: 11%;
    }

    .secondLegend {
        width: 100%;
        /* border: 1px solid red; */
        box-sizing: border-box;
        padding-left: 2%;
    }

    .parasecondLegend {
        font-size: 15px;
        font-family: Poppins;
        font-weight: 600;
        line-height: 20.84px;
        letter-spacing: 4%;
        color: #462F16;
        width: 100%;
    }

    /* .secondfirstmainsection {
        background-image: url("../../assets/images/lore-and-legend/HandmadePaper_Texture_Wide 2.png");
        background-repeat: no-repeat;
        background-size: 100vw 100vh;
        height: 70%;
        width: 80%;
        margin: auto;
        position: relative;
        top: 20%;
        display: flex;
        flex-direction: column-reverse;
    } */
    .secondfirstmainsection {
        background-image: url("../../assets/images/lore-and-legend/HandmadePaper_Texture_Wide 2.png");
        background-repeat: no-repeat;
        background-size: auto auto;
        height: 70%;
        width: 80%;
        margin: auto;
        position: relative;
        top: 20%;
        display: flex;
        flex-direction: column-reverse;
    }

    /* .conatantsection {
        border: 0px solid red;
        width: 100%;
        padding: 0px 0px;
        height: 55%;
        text-align: center;
    } */
    .conatantsection {
        /* border: 1px solid red; */
        width: 100%;
        padding: 0px 0px;
        height: 55%;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }


    .conatantimagesection {
        /* border: 1px solid red; */
        width: 100%;
        height: 45%;
    }

    .titlefont {
        font-family: Averia Libre;
        font-weight: 700;
        font-size: 34px;
        line-height: 33.76px;
    }

    /* .titlepara {
        font-size: 15px;
        line-height: 16px;
        font-weight: 400;
        height: 69%;
        width: 90%;
        margin: 0% auto;
        margin-top: 2%;
        text-align: center;
        border: 0px solid red;
    } */

    .titlepara {
        font-size: 15px;
        line-height: 16px;
        font-weight: 400;
        height: auto;
        width: 90%;
        margin: 0% auto;
        margin-top: 2%;
        text-align: center;
    }

    .LegendLoreAudio {
        font-size: 12px;
        padding: 11px 11px;
        left: calc(50% - 75px);
    }

    #firsttitlefont {
        position: absolute;
        top: -96px;
        text-align: center;
        width: 112%;
        /* border: 1px solid; */
        left: -7%;
    }

    /* #firstimage {
        height: 90%;
        width: 70%;
        margin: 5% 15%;
    } */

    .legendloremenu {
        padding: 10px 7px 0px 7px;
    }

}

@media screen and (min-width: 280px) and (max-width: 767px) {

    .LegendLoreimges {

        top: 22%;
    }

    #firstimage {
        height: auto;
        width: auto;
        margin: 0% auto;
    }
}

@media screen and (min-device-width: 576px) and (max-device-width: 991px) {

    #navlogoimage {
        width: 16vw !important;
        height: 13vh !important;
    }

    #navlogoimagesecond {
        width: 10vw !important;
    }

    .LegendLoreimges {
        display: flex;
        flex-direction: column;
        /* border: 1px solid blueviolet; */
        width: 80%;
        margin: 0% auto;
    }

    .firstLegend {
        width: 90%;
        margin-top: -17%;
        /* border: 1px solid blueviolet; */
        box-sizing: border-box;
        margin-left: 11%;
    }

    .secondLegend {
        width: 100%;
        /* border: 1px solid red; */
        box-sizing: border-box;
        padding-left: 2%;
    }

    .parasecondLegend {
        font-size: 20px;
        font-family: Poppins;
        font-weight: 600;
        line-height: none;
        letter-spacing: 4%;
        color: #462F16;
        width: 100%;
    }

    .secondfirstmainsection {
        background-image: url("../../assets/images/lore-and-legend/HandmadePaper_Texture_Wide 2.png");
        background-repeat: no-repeat;
        background-size: 100vw 100vh;
        height: 70%;
        width: 75%;
        margin: auto;
        position: relative;
        top: 20%;
        display: flex;
        flex-direction: column-reverse;
    }

    .conatantsection {
        /* border: 1px solid red; */
        width: 100%;
        padding: 0px 0px;
        height: 55%;
        text-align: center;
    }

    .conatantimagesection {
        /* border: 1px solid red; */
        width: 100%;
        height: 45%;
    }

    .titlefont {
        font-family: Averia Libre;
        font-weight: 700;
        font-size: 60px;
        position: relative;
        top: 6%;
        line-height: 66.76px;
    }

    .titlepara {
        font-size: 25px;
        line-height: 20.24px;
        font-weight: 400;
        height: 62%;
        width: 90%;
        margin: 0% auto;
        margin-top: 4%;
        text-align: center;
        /* border: 1px solid red; */
    }

    #firsttitlefont {
        position: absolute;
        top: -135px;
        text-align: center;
        width: 112%;
        /* border: 1px solid; */
        left: -7%;
    }

    #firstimage {
        height: 90%;
        width: 70%;
        margin: 5% 15%;
    }

    .LegendLoreAudio {
        font-size: 26px;
        padding: 11px 11px;
        left: calc(46% - 100px);
    }

}


@media screen and (min-device-width: 748px) and (max-device-width: 1440px) {
    .titlepara {
        font-size: 22px;
        width: 80%;
        height: 50%;
        line-height: 23px;
    }

    .titlefont {
        /* font-family: Averia Libre; */
        /* font-weight: 700; */
        font-size: 45px;
        /* position: relative; */
        top: 4%;
        /* line-height: 66.76px; */
    }

    .conatantsection {
        /* border: 1px solid red; */
        /* width: 50%; */
        padding: 35px 26px;
    }

    .LegendLoreAudio {
        position: absolute;
        bottom: 30px;
    }

    .LegendLoreAudio {
        font-size: 2vh;
        padding: 7px 7px;
    }

    #firstimage {
        height: auto;
        width: auto;
        margin: 0% auto;
    }

}













@media(min-width:280px) and (max-width:767px) {

    .loreshowhideDesktop {

        display: none !important;
    }

    .loreshowhideMobile {
        display: block !important;
    }

}

@media(min-width:768px) and (max-width:1023px) {
    .loreshowhideDesktop {

        display: none !important;
    }

    .loreshowhideMobile {
        display: block !important;
    }
}