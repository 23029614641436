#section-menu {
    position: absolute;
    z-index: 9999999;
    background-color: beige;
    opacity: 0.1;
    background-color: #ffffffe7;
    /* background-image: url("../../assets/images/landing-page/menu-bg-with-overlay.jpg"); */
    background-size: cover;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    animation: fade-in 0.2s ease forwards;
    height: 100vh;
}

#section-menu-container {
    margin-top: 20vh;
}

.close-button {
    position: absolute;
    right: 6vw;
    top: 6vh;
    cursor: pointer;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.section-menu-option {
    margin: 0 3vw 10vh 4vw;
    font-family: "Poppins", sans-serif !important;
    color: rgba(0, 0, 0, 1);
    background-color: transparent;
    border-color: transparent;
    font-size: 3.4vh;
    font-style: medium;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
    transition: transform 0.5s;
}

.section-menu-row {
    display: flex;
    justify-content: center;
}

.desktopshowsetionmenu {
    display: block !important;
}

.MobileandIpadshowsetionmenu {
    display: none !important;
}


@media screen and (min-width: 280px) and (max-width: 767px) {
    #section-menu {
        position: fixed;
        z-index: 9999999;
    }

    .desktopshowsetionmenu {
        display: none !important;
    }

    .MobileandIpadshowsetionmenu {
        display: block !important;
    }

    .section-menu-row-first {
        display: flex;
        justify-content: center;
    }

    .section-menu-row {
        display: flex;
        justify-content: center;
    }

    .section-menu-link {
        width: 90vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
    }

    .section-disclaimer {
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        /* margin: 10% auto; */
        position: absolute;
        bottom: 7%;
        /* left: 22%; */

    }

    .section-menu-img {
        width: 35px;
        height: 22px;
    }

    .section-menu-option {
        margin: 0vh 0vw 0vh 0vw;
        font-family: "Poppins", sans-serif !important;
        color: rgba(0, 0, 0, 1);
        background-color: transparent;
        border-color: transparent;
        font-size: 3.4vh;
        font-style: medium;
        font-weight: 500;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;
        cursor: pointer;
        transition: transform 0.5s;
    }

    #section-menu-container {
        margin-top: 10vh;
        text-align: left;
    }

    .section-fontdisclaimer {
        font-size: 21px !important;
    }
    .section-BirdIcon{
        width: 27% !important;
    }
    .section-hading-Menu{
        font-size: 28px;
    }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    #section-menu {
        position: fixed;
    }

    .desktopshowsetionmenu {
        display: none !important;
    }

    .MobileandIpadshowsetionmenu {
        display: block !important;
    }

    .section-menu-option {
        margin: 2vh 0vw 2vh 0vw;
        font-family: "Poppins", sans-serif !important;
        color: rgba(0, 0, 0, 1);
        background-color: transparent;
        border-color: transparent;
        font-size: 3.4vh;
        font-style: medium;
        font-weight: 500;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;
        cursor: pointer;
        transition: transform 0.5s;
    }

    #section-menu-container {
        margin-top: 8vh;
        text-align: left;
    }

    .section-menu-row-first {
        display: flex;
        justify-content: center;
    }

    .section-menu-row {
        display: flex;
        justify-content: center;
    }

    .section-menu-link {
        width: 65vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
    }

    .section-disclaimer {
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        /* margin: 10% auto; */
        position: absolute;
        bottom: 7%;
        /* left: 22%; */

    }

    .section-fontdisclaimer {
        font-size: 36px !important;
    }

    .section-menu-img {
        width: 60px;
        height: 40px;
    }

    .section-hading-Menu {
        font-size: 50px;
    }

    .section-BirdIcon {
        width: 17%;
    }
}