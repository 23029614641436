

.slide-init{
    display:none;
    visibility:hidden
}

.fade-in-slide{
    animation:fadeIn 2s;
    animation-fill-mode: forwards;
}


.fade-in-slide-delay {
    animation:fadeIn 2s;
    animation-fill-mode: forwards;
    animation-delay: 2s;
}

.fade-out-slide-delay {
    animation:fadeOut 2s;
    animation-fill-mode: forwards;
    animation-delay: 2s;
}

.translate-up {
    width: 100vw;
    text-align: center;
    position:relative;
    animation: slideUp 2s;
    animation-fill-mode: forwards;
}

.fade-out-slide{
    animation:fadeOut 2s;
    animation-fill-mode: forwards;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes slideUp {
    0% { bottom: -10%; }
    100% { bottom: 0%; }
}