@import url("https://fonts.googleapis.com/css2?family=Averia+Libre&display=swap");

body {
  font-family: "Averia Libre", cursive;
  color: white;
}

#pg-two {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url("../../assets/images/landing-page/screen-two22.jpg");
  background-size: cover;
}

.skip {
  color: white;
  background-color: transparent;
  border-color: transparent;
  font-size: 1.1vw;
  position: fixed;
  bottom: 35px;
  right: 3.25vw;
  text-decoration: underline;
  cursor: pointer;
}

#content-two {
  font-size: 2vw;
  width: 34vw;
  height: 17vh;
  position: fixed;
  top: 45vh;
  right: 3.25vw;
  text-align: right;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .skip {
    font-size: 5.1vw;
  }

  #content-two {
    font-size: 8vw;
    width: 90% !important;
    top: 25vh;
  }
  #pg-two {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: url("../../assets/images/landing-page/screen-two.jpg");
    background-size: cover;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .skip {
    font-size: 5vw;
  }

  #content-two {
    font-size: 7vw;
    width: 90% !important;
    top: 5vh;
  }
  #pg-two {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: url("../../assets/images/landing-page/screen-two.jpg");
    background-size: cover;
  }

}