@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

body {
  margin: 0;
}

#pg-six,
#overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-size: cover;
}

#pg-six {
  /* opacity: 0; */
  overflow: hidden;
  background-image: url("../../assets/images/landing-page/screen-four.jpg");
}

#overlay {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background: linear-gradient(179.95deg,
      rgba(35, 37, 24, 0.12) 0.04%,
      #23291e 87.47%);
}

#named-logo,
.unnamed-logo {
  /* width: 13vh;
  height: 11vh;
  position: fixed;
  top: 2.9vh;
  left: 1vw; */

  /* width: 12vh;
  height: 10vh;
  position: fixed;
  top: 4.5vh;
  left: 3.5vh; */

  width: 11vh;
  height: 9vh;
  position: fixed;
  top: 2.5vh;
  left: 2.5vh;
  
}

.songsofnaturelogo {
  position: fixed;
  top: 4vh;
  left: 1.5vw;
}



.navbar-nav {
  float: right;
  display: flex;
  flex-direction: row;
  padding: 2vh 8vw;
  position: relative;
}

.landing-nav-item,
.landing-nav-link {
  color: rgba(255, 255, 255, 1) !important;
  text-decoration: none !important;
  padding: 0vh 1vw 0vh 0vw;
  font-family: "Poppins", sans-serif;
  font-size: 2.7vh;
  font-weight: 600px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  height: fit-content;
}

#aboutus {
  margin: 5vh -8vw 0vh 0vw;
}

.landing-menu {
  width: 100vw;
  text-align: center;
  position: relative;
  bottom: 0%
}

.menu-option {
  margin: 0 3vw 8vh 4vw;
  font-family: "Poppins", sans-serif !important;
  color: rgba(255, 255, 255, 1);
  background-color: transparent;
  border-color: transparent;
  font-size: 3.4vh;
  font-style: medium;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  transition: transform 0.5s;
}

#natures-symphony-but:hover,
#legend-lore-but:hover,
#birds-in-music-but:hover,
#songs-of-nature-but:hover {
  box-shadow: 5px 10px 20px black;
  transform: translateY(-20px);
}

#disclaimer-button {
  font-family: "Poppins", sans-serif !important;
  color: white;
  background-color: transparent;
  border-color: transparent;
  font-size: 2vh;
  margin-bottom: 6vh;
  cursor: pointer;
}

#menu-overlay {
  align-items: flex-end;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  #aboutus {
    margin: 5vh -21vw 0vh 0vw;
  }

  /* .songsofnaturelogo {
    position: fixed;
    top: 2%;
    left: 3%;
    height: 6.5% !important;
    width: 14% !important;
} */
.songsofnaturelogo{
  /* height: 57px;
  margin-top: -2vh;
  margin-left: 3.1vw;
  width: 66px; */

  height: 50px;
  margin-top: -1vh;
  margin-left: 1.1vh;
  width: 7vh;
}

  .navbar-nav {
    float: right;
    display: flex;
    flex-direction: row;
    padding: 0vh 8vw;
    position: relative;
  }
  #named-logo,
.unnamed-logo {
  /* top: 3vh;
  width: 7vh;
  height: 6vh;
  left: 5.8vw; */
  top: 2.8vh;
  width: 8.5vh;
  height: 7.2vh;
  left: 5.8vw;
}

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #aboutus {
    margin: 5vh -14vw 0vh 0vw;
  }

  .songsofnaturelogo {
    position: fixed;
    top: 2vh;
    left: 3%;
  }

  .navbar-nav {
    float: right;
    display: flex;
    flex-direction: row;
    padding: 0vh 8vw;
    position: relative;
  }
}