@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

#disclaimer {
  position: absolute;
  z-index: 2;
  background-image: url("../../assets/images/landing-page/menu-bg-with-overlay.jpg");
  background-size: cover;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  animation: fade-in 0.2s ease forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#disclaimer-heading {
  top: 10vh;
}

#disclaimer-content {
  text-align: center;
  width: 45vw;
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  #disclaimer {
    position: absolute;
    z-index: 2;
    background-image: url("../../assets/images/landing-page/menu-bg-with-overlay.jpg");
    background-size: cover;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fade-in 0.2s ease forwards;
  }

  #disclaimer-content {
    text-align: center;
    width: 90vw;
    margin-top: 44%;
  }

  #disclaimer-heading {
    top: 12vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #disclaimer {
    position: absolute;
    z-index: 2;
    background-image: url("../../assets/images/landing-page/menu-bg-with-overlay.jpg");
    background-size: cover;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fade-in 0.2s ease forwards;
  }

  #disclaimer-content {
    text-align: center;
    width: 90vw;
    margin-top: 0%;
  }

  #disclaimer-heading {
    top: 12vh;
  }
}