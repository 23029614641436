@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

#natures-symphony-desc,
#birds-in-music-desc,
#legend-lore-desc,
#songs-of-nature-desc {
  position: absolute;
  z-index: 2;
  background-color: beige;
  opacity: 0.1;
  background-color: #ffffffe7;
  /* background-image: url("../../assets/images/landing-page/menu-bg-with-overlay.jpg"); */
  background-size: cover;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
  animation: fade-in 0.2s ease forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#natures-symphony-visit-but, #natures-symphony-visit-but-safari {
  bottom: 10%;
  left: 4.5%;
}

#natures-symphony-visit-but,
#natures-symphony-visit-but-safari,
#birds-in-music-visit-but,
#birds-in-music-visit-but-safari,
#legend-lore-visit-but,
#legend-lore-visit-but-safari,
#songs-of-nature-visit-but,
#songs-of-nature-visit-but-safari {
  font-size: 2.6vh;
  position: absolute;
  z-index: 10;
  color: black !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.section-heading {
  font-family: "Poppins", sans-serif !important;
  font-style: medium;
  font-weight: 600;
  font-size: 3.6vh;
  color: rgba(35, 42, 30, 1);
  position: absolute;
}

.section-description {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-size: 2vh;
  color: rgba(35, 42, 30, 1);
  position: absolute;
}

#desc-one {
  top: 37vh;
  left: 9vw;
  width: 18vw;
}

#chakra {
  margin: 0vh 45vw;
}

#birdwoman {
  margin: 0vh 58vw;
}

.close-button {
  position: absolute;
  right: 6vw;
  top: 6vh;
  cursor: pointer;
  z-index: 1;
}

#natures-symphony-heading {
  top: 27vh;
  left: 9vw;
}

#natures-symphony-heading,
#birds-in-music-heading,
#legend-lore-heading,
#songs-of-nature-heading {
  animation: slide-up 1s ease forwards;
}

@keyframes slide-up {
  0% {
    transform: translateY(35vh);
    opacity: 0;
  }

  100% {
    transform: translateY(2vh);
    opacity: 1;
  }
}

#birds-in-music-visit-but, #birds-in-music-visit-but-safari {
  bottom: 10%;
  left: 54%;
}


#birds-in-music-heading {
  top: 27vh;
  left: 58.5vw;
}

#desc-three {
  top: 37vh;
  left: 58.5vw;
  width: 18vw;
}

#instrument {
  margin: 0vh 12vw;
}

#legend-lore-heading {
  top: 27vh;
  left: 34vw;
}

#desc-two {
  top: 37vh;
  width: 18vw;
  /* height: 50px; */
  left: 34vw;
}

#legend-lore-visit-but, #legend-lore-visit-but-safari {
  bottom: 10%;
  left: 29.5%;
}

#songs-of-nature-heading {
  top: 27vh;
  left: 79vw;
}

#desc-four {
  top: 37vh;
  left: 79vw;
  width: 15vw;
}

#songs-of-nature-visit-but, #songs-of-nature-visit-but-safari {
  bottom: 10%;
  left: 75%;
}

#kuruvi {
  margin: 0vh 22vw;
}

.illustration {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 52vh 0vw;
}

@media screen and (min-width: 280px) and (max-width: 767px) {

  #chakra {
    margin: 0vh 34vw;
    width: 70%;
  }

  #natures-symphony-heading {
    top: 17vh;
    left: 9vw;
  }

  #desc-one {
    top: 32vh;
    left: 9vw;
    width: 24vw;
  }

  #natures-symphony-visit-but {
    bottom: 4%;
    left: 3.5%;
  }

  #natures-symphony-visit-but-safari {
    left: 0vh;
  }

  #birdwoman {
    margin: 0vh 54vw;
    width: 50%;
  }

  #legend-lore-heading {
    top: 24vh;
    left: 11vw;
  }

  #desc-two {
    top: 36vh;
    left: 11vw;
    width: 20vw;
  }

  #legend-lore-visit-but {
    bottom: 2%;
    left: 4.5%;
  }

  #legend-lore-visit-but-safari {
    bottom: 2%;
    left: 2%;
  }
  #instrument {
    position: relative;
    margin: 0vh 55vw;
    width: 40%;
    top: -9vh;
    
  }

  #desc-three {
    top: 35vh;
    left: 5.5vw;
    width: 31vw;
  }

  #birds-in-music-heading {
    top: 18vh;
    width: 22vh;
    left: 6.5vw;
  }

  #birds-in-music-visit-but {
    bottom: 4%;
    left: 0%;
  }

  #birds-in-music-visit-but-safari {
    bottom: 4%;
    left: -3.5%
  }

  #kuruvi {
    position: relative;
    margin: 0vh 0vw;
    width: 40%;
    left: 27vh;
  }

  #songs-of-nature-heading {
    top: 31vh;
    left: 6vw;
    width: 27vh;
  }

  #desc-four {
    top: 48vh;
    left: 6vw;
    width: 46vw;
  }

  #songs-of-nature-visit-but {
    bottom: 5%;
    left: 0%;
  }

  #songs-of-nature-visit-but-safari {
    bottom: 5%;
    left:-3%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #chakra {
    margin: 0vh 34vw;
    width: 70%;
  }

  #natures-symphony-heading {
    top: 17vh;
    left: 9vw;
  }

  #desc-one {
    top: 34vh;
    left: 9vw;
    width: 18vw;
  }

  #birdwoman {
    margin: 0vh 58vw;
    width: 50%;
  }

  #legend-lore-heading {
    top: 27vh;
    left: 11vw;
  }

  #desc-two {
    top: 37vh;
    left: 11vw;
    width: 20vw;
  }

  #legend-lore-visit-but, #legend-lore-visit-but-safari {
    bottom: 10%;
    left: 7.5%;
  }

  #instrument {
    margin: 0vh 12vw;
    width: 40%;
  }

  #kuruvi {
    margin: 0vh 0vw;
    width: 40%;
  }

  #songs-of-nature-heading {
    top: 27vh;
    left: 53vw;
  }

  #desc-four {
    top: 39vh;
    left: 54vw;
    width: 40vw;
  }

  #songs-of-nature-visit-but, #songs-of-nature-visit-but-safari {
    bottom: 18%;
    left: 49%;
  }
}