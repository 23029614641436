@media only screen and (min-width: 500px){
  .modal-container {
    width: 100%;
    height: 85%;
    border-radius: 10px;
    transition: 0.3s;
    transform-style: preserve-3d;
    position: relative;
    cursor: pointer;
    
    .modal-img {
      width: 100%;
      height: 100%;
    }
    
    .modal-face {
      backface-visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      &.modal-back-face {
        transform: rotateY(180deg);
      }
    }
  
    &.is-flipped {
      transform: rotateY(180deg);
    //   box-shadow: 10px 4px 4px 2px rgba(1, 1, 0, 0.5);
    }
  
    &.is-inactive {
      // visibility: hidden;
      
      opacity: 0;
    }
  }
}
@media only screen and (max-width: 700px){
.modal-container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: 0.3s;
    transform-style: preserve-3d;
    position: relative;
    cursor: pointer;
    
    .modal-img {
      width: 100%;
      height: 100%;
    }
    
    .modal-face {
      backface-visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      &.modal-back-face {
        transform: rotateY(180deg);
      }
    }
  
    &.is-flipped {
      transform: rotateY(180deg);
    //   box-shadow: 10px 4px 4px 2px rgba(1, 1, 0, 0.5);
    }
  
    &.is-inactive {
      // visibility: hidden;
      opacity: 0;
    }
  }
}