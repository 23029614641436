.mainSyrinx {
    display: flex;
    width: 77.34vw;
    position: relative;
    overflow: hidden;
    z-index: -1;
    height: 100vh;
    background-color: #333333;
}

.mainSyrinxSkin {
    display: flex;
    width: 175vw;
    position: relative;
    overflow: hidden;
    z-index: 1;
    height: 100vh;
    background-color: #F4EACE;
}

.mainSyrinxSkin1 {
    display: flex;
    width: 175vw;
    position: relative;
    /* z-index: -1; */
    height: 100vh;
    background-color: #F4EACE;
}

.syrinxHeading {
    font-family: 'Averia Libre';
    font-style: normal;
    font-weight: 700;
    font-size: 12vh;
    line-height: 102%;
    width: 39vw;
    height: 23.98vh;
    letter-spacing: 3px;
    margin-left: 13.19vw;
    margin-bottom: 3vh;
    margin-top: 17.18vh;
    position: absolute;
    color: #FFFFFF;
}



.syrinxHeading1 {
    font-family: 'Averia Libre';
    font-style: normal;
    font-weight: 700;
    font-size: 12vh;
    line-height: 99%;
    letter-spacing: 3px;
    width: 37.12vw;
    max-height: 16.7vh;
    margin-left: 32.83vw;
    margin-top: 22vh;
    position: absolute;
    color: black;
}

.syrinxPara {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2.5vh;
    line-height: 4vh;
    width: 33.29vw;
    height: 19.12vh;
    color: #FFFFFF;
    margin-left: 13.19vw;
    margin-top: 47vh;
    position: absolute;
}

#skinPara {
    color: black;
    margin-left: 32.83vw;
    width: 26vw;
    margin-top: 50vh;
    font-size: 2.4vh;
    position: absolute;
    line-height: 3.6vh;
}

.videoButton {
    background: #D56D54;
    width: 13.11vw;
    height: 5.44vh;
    border: none;
    color: #FFFFFF;
    margin-top: 85vh;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2vh;
    line-height: 24px;
    display: flex;
    padding: 10px 10px 10px 10px;
    align-items: center;
    cursor: pointer;
    margin-left: 13.19vw;

}

.videoButton:hover {
    background-color: #E3927E;
}

.videoIcon {
    margin-top: 1vh;
    margin-right: 12px;
    width: 30px;

}

#skinVideoButton {
    margin-left: 32.83vw;
    margin-top: 77vh;
}

.syrinxImg {
    height: 89.44vh;
    width: 34.06%;
    margin-left: 69.3vw;
    margin-top: 11.3vh;
    z-index: 100;
    position: absolute;
}

.syrinxImg1 {
    height: 89.44vh;
    width: 16.06%;
    margin-left: -8.8vw;
    margin-top: 11.9vh;
    position: absolute;
}

.strokeBlue {
    width: 37vw;
    height: 100vh;
    position: absolute;
    margin-left: 79vw;
}

.factoidLotus {
    position: absolute;
    margin-left: 84vw;
    width: 33.33vw;
    height: 33.62vh;
}

.factoidHeading {
    position: absolute;
    margin-left: 86.55vw;
    width: 24.66vw;
    height: 11.57vh;
    margin-top: 39.94vh;
    z-index: 100;
}

#factoidPara {

    margin-left: 88.35vw;
    width: 20.26vw;
    height: 25.09vh;
    margin-top: 54vh;
    font-size: 2.5vh;
    position: absolute;
    line-height: 3.4vh;
    text-align: center;
}

#syrinxHeading2 {

    line-height: 99%;
    letter-spacing: 6px;
    width: 40.86vw;
    height: 17.7vh;
    margin-left: 122.83vw;
    margin-top: 26vh;
}

.courting {
    position: absolute;
    width: 45.75vw;
    height: 48.79vh;
    left: 156.9vw;
    top: 11vh;
}

.mobilehideshowimg {
    display: none !important;
}

.descohideshowimg {
    display: block !important;
}

.desktopFactoid {
    display: block ! important;
}

.mobileipadFactoid {
    display: none ! important;
}

.desktopDrawn {
    display: block !important;
}

.mobileipadDrawn {
    display: none !important;
}


@media(min-width:280px) and (max-width:767px) {

    .SyrinxMobilemain {
        width: 100vw;
        height: 80vh;
        background-color: #333333;
        position: relative;
    }

    .reallysingHeading {
        position: absolute;
        top: 8%;
        left: 9%;
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        margin: 0;
        width: 80%;
    }

    .reallysingpara {
        position: absolute;
        top: 25%;
        width: 87%;
        margin: 0% 10%;
    }

    .MagpieRobinpara {
        width: 63%;
        margin-top: 12%;
    }

    .reallysingpara p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        /* font-size: 12px; */
        /* line-height: 18px; */
        color: #FFFFFF;
        font-size: 2vh;
        line-height: 3vh;
    }

    .mainSyrinxSkin1 {
        display: flex;
        width: 175vw;
        position: relative;
        /* z-index: -1; */
        height: 85vh;
        background-color: #F4EACE;
        /* border: 1px solid red; */
    }

    /* .syrinxHeading {
    font-family: "Averia Libre";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 102%;
    width: 75vw;
    height: 23.98vh;
    letter-spacing: 3px;
    margin-left: 10%;
    margin-bottom: 3vh;
    margin-top: 8.18vh;
    position: absolute;
    color: #ffffff;
} */

    .syrinxPara {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 2vh;
        line-height: 3vh;
        width: 80.29vw;
        height: 19.12vh;
        color: #FFFFFF;
        margin-left: 11.19vw;
        margin-top: 30vh;
        position: absolute;
    }

    .SyrinxMobilebutton {
        position: absolute;
        bottom: 10%;
    }

    .videoButtonnewmob {
        background: #D56D54;
        width: 48.11vw;
        height: 5.44vh;
        border: none;
        color: #FFFFFF;
        /* margin-top: 85vh; */
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 2vh;
        line-height: 24px;
        display: flex;
        padding: 10px 10px 10px 10px;
        align-items: center;
        cursor: pointer;
        margin-left: 21%;
    }

    .videoButton {
        background: #D56D54;
        width: 48.11vw;
        height: 5.44vh;
        border: none;
        color: #FFFFFF;
        margin-top: 60vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 2vh;
        line-height: 24px;
        display: flex;
        padding: 10px 10px 10px 10px;
        align-items: center;
        cursor: pointer;
        margin-left: 11.19vw;
    }

    .mainSyrinx {
        width: 100vw;
        height: 80vh;
    }


    .syrinxImg {
        /* height: 36.44vh; */
        height: auto;
        width: 38.06%;
        /* height: auto; */
        /* margin-left: 63.3vw; */
        /* margin-top: 64.3vh; */
        /* z-index: -1; */
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .syrinxImg1 {
        display: none !important;
    }

    .syrinxHeading1 {
        font-weight: 700;
        font-size: 36px;
        line-height: 99%;
        letter-spacing: 3px;
        width: 90vw;
        max-height: 16.7vh;
        margin-left: 5%;
        margin-top: 5vh;
        position: absolute;

    }

    .syrinxPara {
        font-weight: 400;
        font-size: 2vh;
        line-height: 3vh;
        width: 82vw;
        height: 19.12vh;
        margin-left: 10%;
        margin-top: 21vh;
        position: absolute;
    }

    #skinPara {
        color: black;
        margin-left: 5%;
        width: 80vw;
        margin-top: 16vh;
        font-size: 2vh;
        /* margin-top: 15vh; */
        /* font-size: 2.4vh; */
        position: absolute;
        line-height: 3vh;
    }

    .mobilehideshowimg {
        display: block !important;
        z-index: -1;
    }

    .descohideshowimg {
        display: none !important;
    }

    .strokeBlue {
        width: 100vw;
        height: 50vh;
        position: absolute;
        top: 51%;
        margin-left: 0%;
    }

    #skinVideoButton {
        margin-left: 8.83vw;
        margin-top: 42vh;
    }

    #factoidPara {
        margin-left: 3.35vw;
        width: 94.26vw;
        height: 25.09vh;
        margin-top: 76vh;
        font-size: 2vh;
        position: absolute;
        line-height: 2.4vh;
        text-align: center;
    }

    .factoidHeading {
        position: absolute;
        margin-left: 21.55vw;
        width: 59.66vw;
        height: 11.57vh;
        margin-top: 62.94vh;
        z-index: 100;
    }

    .factoidLotus {
        position: absolute;
        top: 51%;
        margin-left: 58vw;
        width: 36%;
        height: auto;
    }

    #syrinxHeading2new {
        line-height: 99%;
        letter-spacing: 6px;
        width: 93.86vw;
        height: 43.7vh;
        margin-left: 6.83vw;
        margin-top: 8vh;
    }

    .courtingnew {
        position: absolute;
        width: 110.75vw;
        height: auto;
        left: -9.1vw;
        /* top: 75vh; */
        bottom: -6%;
    }

    .count {
        position: absolute;
        left: 6.17vw;
        top: 47vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12.1vh;
        line-height: 192px;
        color: #D56D54;
    }

    .para {
        position: absolute ! important;
        width: 79.5vw ! important;
        height: 13.51vh ! important;
        left: 15.7vw ! important;
        top: 39vh ! important;
        font-family: 'Poppins' ! important;
        font-style: normal ! important;
        font-weight: 400 ! important;
        font-size: 2vh ! important;
        line-height: 3vh !important;
        color: BLACK ! important;
        text-align: right ! important;

    }

    .count {
        position: absolute ! important;
        left: 72.17vw ! important;
        top: 12vh ! important;
        font-family: 'Poppins' ! important;
        font-style: normal ! important;
        font-weight: 400 ! important;
        font-size: 12.1vh ! important;
        line-height: 192px ! important;
        color: #D56D54 ! important;
    }

    .desktopFactoid {
        display: none ! important;
    }

    .mobileipadFactoid {
        display: block ! important;
    }

    .mobileipadFactoid {
        position: absolute;
        margin-top: 59vh;
        width: 100vw;
        text-align: center;
    }

    .mobileipadFactoid h1 {
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #D56D54;
    }

    .mobileipadFactoid h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 20px !important;
    }

    .desktopDrawn {
        display: none !important;
    }

    .mobileipadDrawn {
        display: block !important;
        position: absolute;
        margin-top: 20vh;
        width: 100vw;
        text-align: center;
    }

    .mobileipadDrawn h1 {
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #D56D54;
    }

    .mobileipadDrawn h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 20px !important;
    }
}

@media(min-width:768px) and (max-width:1023px) {

    .SyrinxMobilemain {
        width: 100vw;
        height: 80vh;
        background-color: #333333;
        position: relative;
    }

    .reallysingHeading {
        position: absolute;
        top: 9%;
        left: 6%;
        font-family: 'Averia Libre';
        font-style: normal;
        font-size: 60px;
        line-height: 102%;
        margin: 0;
    }

    .reallysingpara {

        position: absolute;
        top: 20%;
        width: 90%;
        margin: 0% 5%;
    }

    .MagpieRobinpara {
        width: 62%;
    }

    .reallysingpara p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        /* font-size: 12px; */
        /* line-height: 18px; */
        color: #FFFFFF;
        font-size: 2vh;
        line-height: 3vh;
    }

    .SyrinxMobilebutton {
        position: absolute;
        bottom: 15%;
    }

    .videoButtonnewmob {
        background: #D56D54;
        width: 30.11vw;
        height: 5.44vh;
        border: none;
        color: #FFFFFF;
        /* margin-top: 85vh; */
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 2vh;
        line-height: 24px;
        display: flex;
        padding: 10px 10px 10px 10px;
        align-items: center;
        cursor: pointer;
        margin-left: 15%;
    }

    .desktopFactoid {
        display: none ! important;
    }


    .mobileipadFactoid {
        display: block ! important;
        position: absolute;
        margin-top: 61vh;
        width: 100vw;
        text-align: center;
    }

    .mobileipadFactoid h1 {
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        font-size: 39px;
        color: #D56D54;
    }

    .mobileipadFactoid h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 43px !important;
    }

    .desktopDrawn {
        display: none !important;
    }

    .mobileipadDrawn {
        display: block !important;
        position: absolute;
        margin-top: 14vh;
        width: 100vw;
        text-align: center;
        z-index: 1;
    }

    .mobileipadDrawn h1 {
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        font-size: 39px;
        color: #D56D54;
    }

    .mobileipadDrawn h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 43px !important;
    }

    .syrinxHeading {
        font-family: "Averia Libre";
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 102%;
        width: 75vw;
        height: 23.98vh;
        letter-spacing: 3px;
        margin-left: 10%;
        margin-bottom: 3vh;
        margin-top: 13.18vh;
        position: absolute;
        color: #ffffff;
    }

    .syrinxHeading1 {
        font-family: 'Averia Libre';
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 99%;
        letter-spacing: 3px;
        width: 96.12vw;
        max-height: 16.7vh;
        margin-left: 4.83vw;
        margin-top: 11vh;
        position: absolute;
        color: black;
    }

    .strokeBlue {
        width: 100vw;
        height: 47vh;
        position: absolute;
        top: 54%;
        margin-left: 0%;
    }

    #skinPara {
        color: black;
        margin-left: 4.83vw;
        width: 91vw;
        margin-top: 22vh;
        font-size: 2.4vh;
        position: absolute;
        line-height: 3vh;
    }

    #skinVideoButton {
        margin-left: 4.83vw;
        margin-top: 41vh;
    }

   

    #factoidPara {
        margin-left: 5%;
        width: 85.26vw;
        height: 25.09vh;
        margin-top: 79vh;
        font-size: 2.5vh;
        position: absolute;
        line-height: 3.4vh;
        text-align: center;
    }

    .factoidHeading {
        position: absolute;
        margin-left: 27.55vw;
        width: 46.66vw;
        height: 11.57vh;
        margin-top: 66.94vh;
        z-index: 100;
    }

    .factoidLotus {
        position: absolute;
        top: 45%;
        margin-left: 70vw;
        width: 30%;
        height: 30%;
    }

    #syrinxHeading2new {
        line-height: 99%;
        letter-spacing: 6px;
        width: 83.86vw;
        height: 43.7vh;
        margin-left: 12.83vw;
        margin-top: 11vh;
    }

    .syrinxPara {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 2.5vh;
        line-height: 4vh;
        width: 80.29vw;
        height: 19.12vh;
        color: #FFFFFF;
        margin-left: 11.19vw;
        margin-top: 29vh;
        position: absolute;
    }

    .count {
        position: absolute ! important;
        left: 74.17vw ! important;
        top: 18vh ! important;
        font-family: 'Poppins' ! important;
        font-style: normal ! important;
        font-weight: 400 ! important;
        font-size: 12.1vh ! important;
        line-height: 192px ! important;
        color: #D56D54 ! important;
    }

    .para {
        position: absolute ! important;
        width: 64.5vw ! important;
        height: 13.51vh ! important;
        left: 24.7vw ! important;
        top: 42vh ! important;
        font-family: 'Poppins' ! important;
        font-style: normal ! important;
        font-weight: 400 ! important;
        font-size: 2.5vh ! important;
        line-height: 30px ! important;
        color: black ! important;
        text-align: right ! important;
    }

    .courtingnew {
        position: absolute ! important;
        width: 99.75vw ! important;
        height: 45.79vh ! important;
        left: -1.1vw ! important;
        top: 61vh ! important;
    }

    .videoButton {
        background: #D56D54;
        width: 30.11vw;
        height: 5.44vh;
        border: none;
        color: #FFFFFF;
        margin-top: 80vh;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 2vh;
        line-height: 24px;
        display: flex;
        padding: 10px 10px 10px 10px;
        align-items: center;
        cursor: pointer;
        margin-left: 13.19vw;
    }

    .mainSyrinx {
        width: 100vw;
    }

    .videoIcon {
        margin-top: 4px;
        margin-right: 12px;
        width: 30px;
    }

    .syrinxImg1 {
        display: none !important;
    }

    .mobilehideshowimg {
        display: block !important;
        z-index: -1;
    }

    .descohideshowimg {
        display: none !important;
    }

    .syrinxImg {
        height: 42.44vh;
        width: 41.06%;
        /* margin-left: 60.3vw;   */
        /* margin-top: 57.3vh;    */
        /* z-index: -2; */
        position: absolute;
        right: 0;
        bottom: 0;
    }

}

@media(min-width:1023px) and (max-width:1300px) {
    .syrinxHeading{
        font-size: 11vh;
    }
    .syrinxHeading1{
        font-size: 10.6vh;
    }
    .videoButton{
        width: 14.11vw;
    }
    #skinPara{
        font-size: 2.3vh;
    }
}