 @media screen and (min-width: 280px) and (max-width: 767px) {
     .Peacockresponsive {
         width: 66%;
     }

     #Peacockimggg {
         width: 48%;
         /* border: 1px solid red; */
     }

     #Swanresponsive {
         width: 70%;
     }

     #BulbulTarangresponsive {
         margin-bottom: -15% !important;
     }

     #BulbulTarangbtn {
         margin-top: 27% !important;
     }

     #Crowresponsive {
         width: 60%;
     }

     #BirdWhistleresponsive {
         margin-top: -7% !important;
     }

     #BirdWhistlebtn {
         margin-top: 26% !important;
     }

     #Whistleresponsive {
         width: 45%;
     }

     #Tausresponsive {
         margin-top: -8% !important;
     }

     #Tausimgresponsive {
         bottom: 26% !important;
         width: 47% !important;
     }

     #Tausbtnresponsive {
         margin-top: 27% !important;
     }

     #MayuriYaazhresponsive {
         margin-top: -8%;
     }

     #MayuriYaazhresponsive {
         margin-top: -4% !important;
     }

     #MayuriYaazhbtn {
         margin-top: 22% !important;
     }

     #MayuriYaazhimg {
         width: 38% !important;
     }

     .mainOutBox {
         border: 0px solid black;
         width: 100%;
         /* height: 800PX; */
         position: relative;
         /* flex-direction: column; */
         align-items: center;
         /* display: flex; */
         height: auto;
     }

     .mainOutBox .firstBox {
         border: 0px solid black;
         width: 100vw;
         height: 100%;
     }

     .mainBox1 .firstBox .firstInnerBox .firstInnerMainBox h2 {
         margin: 0;
     }

     .mainOutBox .firstBox .firstInnerBox .firstInnerMainBox2 {
         display: flex;
     }

     .mainBox1 .firstBox .firstInnerBox .firstInnerMainBox2 h2 {
         position: absolute;
         top: 20px;
         left: 21px;
         width: 57%;
         font-size: 37px;
         width: 50%;
     }

     .mainBox1 .firstBox .firstInnerBox .firstInnerMainBox2 .firstInnerMainBox2img {
         width: 59% !important;
         position: absolute;
         top: 3%;
         right: 5%;
     }

     .mainOutBox .secoundBox .secoundInnerBox .secoundInnerMainBox .audioVideoButton button {
         background: #F4EACE;
         border: none;
         padding: 4px 2px 4px 2px;
         width: 91px;
         border-radius: 50px;
         display: flex;
         align-items: center;
         justify-content: center;
         cursor: pointer;
     }

     .mainOutBox .secoundBox {
         border: 0px solid red;
         width: 100vw;
         padding: 10px 0px;
     }

     .mainOutBox .secoundInnerBox {
         border: 0px solid yellow;
         width: 100%;
         height: 100%;
         position: relative;

     }

     .mainOutBox .secoundInnerBox .secoundInnerMainBox {
         border: 0px solid white;
         position: absolute;
         top: 0;
         width: 100%;
         height: 100%;
         padding: 50px;
         display: flex;
         /* align-items: center; */
         justify-content: center;
         z-index: 1;
     }

     .mainOutBox .secoundInnerBox .secoundInnerMainBox .innertText p {
         font-size: 10px;
         font-family: 'Poppins';
         font-style: normal;
     }

     .mainOutBox .secoundInnerBox .secoundInnerMainBox .innertText .secndpara {
         font-size: 10px;
         font-family: 'Poppins';
         font-style: italic;
     }

     .mainBox2 {
         height: auto;
         margin: 10px auto;
     }

     .mainBox2 .firstBox .firstInnerBox .firstInnerMainBox {
         padding: 0;
     }

     .mainBox2 .firstBox .firstInnerBox .firstInnerMainBox .firstInnerMainBox2img {
         width: 69%;
         position: absolute;
         top: 3%;
         left: 0px;
     }

     .mainBox2 .firstBox .firstInnerBox .firstInnerMainBox h2 {
         position: absolute;
         top: 4%;
         right: 5%;
         font-size: 26px;
         width: 50%;
         text-align: right;
     }

     .mainOutBox .secoundInnerBox .bottomImg {
         position: absolute;
         bottom: 57px;
         right: 0px;
         z-index: -2;
         width: 55%;
     }

     .mainOutBox .secoundInnerBox .bottomImgCrow {
         position: absolute;
         bottom: 55px;
         right: 0%;
         z-index: -2;
         width: 58%;
     }

     .mainBox4 .firstBox .firstInnerBox .firstInnerMainBox {
         display: flex;
         flex-direction: row;
         position: absolute;
         top: 4%;
         /* border: red solid; */
     }

     .mainBox4 .firstBox .firstInnerBox .firstInnerMainBox h2 {
         margin: 16px 0 0 27px;
         width: 15%;
         font-size: 26px;
     }

     .mainBox4 .firstBox .firstInnerBox .firstInnerMainBox img {
         bottom: 0;
         position: relative;
         width: 87%;
         left: 13%;
     }

     .mainBox5 .firstBox .firstInnerBox .firstInnerMainBox {
         display: flex;
         flex-direction: row;
         position: absolute;
         top: 1%;
         /* border: red solid; */
     }

     .mainBox5 .firstBox .firstInnerBox .firstInnerMainBox h2 {
         margin: 75px 0 0 43px;
         width: 19%;
         font-size: 23px;
     }

     .mainBox5 .firstBox .firstInnerBox .firstInnerMainBox img {
         bottom: 0;
         position: relative;
         top: 7%;
         width: 67%;
         left: 32%;
     }

     .mainBox6 .firstBox .firstInnerBox .firstInnerMainBox {
         display: flex;
         flex-direction: row-reverse;
         position: absolute;
         top: 1%;
         /* border: red solid; */
     }

     .mainBox6 .firstBox .firstInnerBox .firstInnerMainBox .tamburo {
         width: 58vw !important;
         position: relative;
         left: 3%;
     }

     .mainBox6 .firstBox .firstInnerBox .firstInnerMainBox h2 {
         /* padding-right: 40PX; */
         font-size: 38px;
         position: absolute;
         /* right: 0; */
         /* left: 74%; */
         text-align: right;
         left: 43vw;
     }

     .mainBox6 .secoundInnerBox .bottomImg {
         bottom: 80px !important;
     }

     .mainBox6 .secoundInnerBox .bottomImgTarpa {
         left: 5% !important;
         top: 47% !important;
     }

     .mainOutBox .secoundInnerBox .secoundInnerMainBox .descriptionTarpa {
         padding: 0 0 77px 0;
     }
 }

 @media screen and (min-width: 280px) and (max-width: 375px) {
   #BulbulTarangresponsive{
        margin-bottom: -30% !important;
   }

 }

 @media screen and (min-width: 376px) and (max-width: 390px) {
     #BulbulTarangresponsive {
         margin-bottom: -19% !important;
     }

 }



 @media screen and (min-width: 768px) and (max-width: 1023px) {
     .Peacockresponsive {
         width: 74%;
         /* border: 1px solid red; */
     }

     #Peacockimggg {
         width: 44%;
         /* border: 1px solid red; */
     }

     #Swanresponsive {
         width: 70%;
     }

     #BulbulTarangresponsive {
         margin-bottom: -33% !important;
     }

     #Crowresponsive {
         width: 64%;
     }

     #BulbulTarangbtn {
         margin-top: 27% !important;
     }

     #BirdWhistleresponsive {
         margin-top: -11% !important;
     }

     #BirdWhistlebtn {
         margin-top: 26% !important;
     }

     #Tausimgresponsive {
         bottom: 26% !important;
     }

     #Whistleresponsive {
         width: 45%;
         top: 41% !important;
     }

     #Tausresponsive {
         margin-top: -20% !important;
     }

     #Tausbtnresponsive {
         margin-top: 27% !important;
     }

     #MayuriYaazhresponsive {
         margin-top: -22% !important;
     }

     #MayuriYaazhbtn {
         margin-top: 35% !important;
     }

     #MayuriYaazhimg {
         width: 38% !important;
     }

     .mainOutBox {
         border: 0px solid black;
         width: 100%;
         /* height: 300px; */
         position: relative;
         /* display: flex; */
         align-items: center;
         height: auto;
     }

     .mainOutBox .firstBox {
         border: 0px solid black;
         width: 100%;
         height: 100%;
     }

     .mainBox1 .firstBox .firstInnerBox .firstInnerMainBox h2 {
         margin: 0;
     }

     .mainOutBox .firstBox .firstInnerBox .firstInnerMainBox2 {
         display: flex;
     }

     .mainBox1 .firstBox .firstInnerBox .firstInnerMainBox2 h2 {
         position: absolute;
         top: 10px;
         left: 65px;
         width: 57%;
         font-size: 80px;
         width: 50%;

     }

     .mainBox1 .firstBox .firstInnerBox .firstInnerMainBox2 .firstInnerMainBox2img {
         width: 59% !important;
         position: absolute;
         top: 3%;
         right: 5%;
     }

     .mainOutBox .secoundBox .secoundInnerBox .secoundInnerMainBox .audioVideoButton button {
         background: #F4EACE;
         border: none;
         padding: 4px 2px 4px 2px;
         border-radius: 50px;
         display: flex;
         align-items: center;
         justify-content: center;
         cursor: pointer;
         font-size: 22px;
         width: 140px;
     }

     .mainOutBox .secoundBox .secoundInnerBox .secoundInnerMainBox .audioVideoButton button img {
         width: 40px;
         height: 50px;
         padding-bottom: 6px;
     }

     .mainOutBox .secoundBox {
         border: 0px solid red;
         width: 100vw;
         padding: 10px 0px;
     }

     .mainOutBox .secoundInnerBox {
         border: 0px solid yellow;
         width: 100%;
         height: 100%;
         position: relative;

     }

     .mainOutBox .secoundInnerBox .secoundInnerMainBox {
         border: 0px solid white;
         position: absolute;
         top: 0;
         width: 100%;
         height: 100%;
         padding: 0px 120px !important;
         display: flex;
         align-items: center;
         justify-content: center;
         z-index: 1;
     }


     .mainOutBox .secoundInnerBox .secoundInnerMainBox .description {
         font-size: 25px;
         min-height: 180px;
         /* background-color: red; */
         font-family: 'Poppins';
         font-style: normal;
     }

     .mainOutBox .secoundInnerBox .secoundInnerMainBox .innertText p {
         font-size: 20px;
         font-family: 'Poppins';
         font-style: normal;
     }

     .mainOutBox .secoundInnerBox .secoundInnerMainBox .innertText .secndpara {
         font-size: 20px;
         font-family: 'Poppins';
         font-style: italic;
     }

     .mainOutBox .secoundInnerBox .secoundInnerMainBox h2 {
         font-size: 46px;
         font-family: 'Averia Libre';
         font-style: normal;
         font-weight: 700;
     }

     .mainBox2 {
         height: auto;
         margin: 50px auto;
     }

     .mainBox2 .firstBox .firstInnerBox .firstInnerMainBox {
         padding: 0;
     }

     .mainBox2 .firstBox .firstInnerBox .firstInnerMainBox .firstInnerMainBox2img {
         width: 69%;
         position: absolute;
         top: 4%;
         left: 4px;
     }

     .mainBox2 .firstBox .firstInnerBox .firstInnerMainBox h2 {
         position: absolute;
         top: 1%;
         right: 5%;
         font-size: 57px;
         width: 50%;
         text-align: right;
     }

     .mainOutBox .secoundInnerBox .bottomImg {
         position: absolute;
         bottom: 110px;
         z-index: -2;
         width: 50%;
         right: 0;
     }

     .mainOutBox .secoundInnerBox .bottomImgCrow {
         position: absolute;
         bottom: 110px;
         /* left: 30%; */
         right: 0;
         z-index: -2;
         width: 60%;
     }


     .mainBox4 .firstBox .firstInnerBox .firstInnerMainBox {
         display: flex;
         flex-direction: row;
         position: absolute;
         top: 4%;
         /* border: red solid; */
     }


     .mainBox4 .firstBox .firstInnerBox .firstInnerMainBox h2 {
         margin: 56px 0 0 73px;
         width: 15%;
         font-size: 42px;
     }

     .mainBox4 .firstBox .firstInnerBox .firstInnerMainBox img {
         bottom: 0;
         position: relative;
         width: 87%;
         left: 12%;
     }

     .mainBox5 .firstBox .firstInnerBox .firstInnerMainBox {
         display: flex;
         flex-direction: row;
         position: absolute;
         top: 1%;
         /* border: red solid; */
     }

     .mainBox5 .firstBox .firstInnerBox .firstInnerMainBox h2 {
         margin: 176px 0 0 75px;
         width: 19%;
         font-size: 48px;
     }

     .mainBox5 .firstBox .firstInnerBox .firstInnerMainBox img {
         bottom: 0;
         position: relative;
         top: 7%;
         width: 67%;
         left: 32%;
     }

     .mainBox6 .firstBox .firstInnerBox .firstInnerMainBox {
         display: flex;
         flex-direction: row-reverse;
         position: absolute;
         top: 1%;
         /* border: red solid; */
     }

     .mainBox6 .firstBox .firstInnerBox .firstInnerMainBox .tamburo {
         width: 58vw !important;
         position: relative;
         left: 3%;
     }

     .mainBox6 .firstBox .firstInnerBox .firstInnerMainBox h2 {
         /* padding-right: 40PX; */
         font-size: 68px;
         position: absolute;
         /* right: 0; */
         left: 78%;
         text-align: right;
     }

     .mainBox6 .secoundInnerBox .bottomImg {
         bottom: 80px !important;
     }

     .mainBox6 .secoundInnerBox .bottomImgTarpa {
         left: 5% !important;
         top: 47% !important;
     }

     .mainOutBox .secoundInnerBox .secoundInnerMainBox .descriptionTarpa {
         padding: 0 0 77px 0;
     }


 }
 @media screen and (min-width: 355px) and (max-width: 365px){

     .mainBox6 .firstBox .firstInnerBox .firstInnerMainBox h2 {
        left: 65%;
    
    }
 }