.htpHeading{
    margin-top: 8%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 5.5vh;
    line-height: 6vh;
    color: #FFFFFF ;
    margin-left: 38.4%;
    
}
.htpsubHeading{
              color: #FFFFFF;
              margin-left: 14.21%;
              font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 2.2vh;
            line-height: 2.5vh;
            }
.content{
    margin-top: 8%;
    width: 90.6%;
}
#objective{
    margin-bottom: 7%;
}
.backButton{
              width: 12vw;
              height: 9vh;
              margin-top: 3%;
              margin-left: 38%;
              background: #FFFFFF;
              box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 25px;
              cursor: pointer;
            }
.bButton{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 3vh;
line-height: 8vh;
}
#continueButton{
  width: 9vw;
              height: 7vh;
              margin-top: 6%;
            margin-left: 36.60%;
              background: #FFFFFF;
              box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 18px;
              cursor: pointer;
             
}
.funFacts{
  font-size: 2.7vh;
  color: #FFFFFF;
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
line-height: 20px;
text-align: center;
}

.funFactsDiv{
width: 70%;
height: 9%;
margin-left: 17%;
margin-top: 7%;
}